import { useEffect } from "react";

import { useErrorHandler } from "react-error-boundary";
import { useQueryClient } from "@tanstack/react-query";

import { isAxiosError } from "~/helpers/isAxiosError";

interface RegisterQueryClientErrorHandlerProps {
  children: JSX.Element;
}

export function RegisterQueryClientErrorHandler({
  children,
}: RegisterQueryClientErrorHandlerProps) {
  const queryClient = useQueryClient();
  const handleError = useErrorHandler();

  useEffect(() => {
    function onError(error: unknown) {
      if (isAxiosError(error) && error.response?.status === 401) {
        return handleError(error);
      }

      return undefined;
    }

    const defaultOptions = queryClient.getDefaultOptions();

    queryClient.setDefaultOptions({
      queries: { ...defaultOptions.queries, onError },
      mutations: { ...defaultOptions.mutations, onError },
    });
  }, [queryClient, handleError]);

  return children;
}
