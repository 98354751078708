import { lazy as m, Suspense as n } from "react";
import { jsx as a } from "react/jsx-runtime";
const u = m(
  () => import("./GammaNLTheme-D_QGwrIQ.js").then((e) => ({
    default: e.GammaNLTheme
  }))
), l = m(
  () => import("./GammaBETheme-DZ7FiqF7.js").then((e) => ({
    default: e.GammaBETheme
  }))
), h = m(
  () => import("./KarweiTheme-BwtB4W3X.js").then((e) => ({
    default: e.KarweiTheme
  }))
);
function s({ children: e, formula: r }) {
  const t = o(r);
  return t ? /* @__PURE__ */ a(n, { fallback: null, children: /* @__PURE__ */ a(t, { children: e }) }) : null;
}
function o(e) {
  switch (e) {
    case "gamma_nl":
      return u;
    case "gamma_be":
      return l;
    case "karwei_nl":
      return h;
    default:
      return null;
  }
}
export {
  s as IntergammaTheme
};
