function r(t, a) {
  if (t.length < 2)
    throw new Error(`Could not get allocation for ${t}`);
  const o = 1 / t.length * a * 100, n = 1 / t.length * (a + 1) * 100;
  return [o, n];
}
function e(t) {
  const a = {};
  for (const [o, n] of t.entries())
    a[n] = {
      allocation: r(t, o)
    };
  return a;
}
const l = {
  // CHECKOUT ------------------------------------------------------------------
  "abtest_dba_1971-disabled-dba_4": {
    scope: "local",
    description: "New CTA buttons",
    variants: {
      "A-control": { allocation: [0, 100] },
      "B-new": { allocation: [100, 100] }
    },
    domain_and_index: "abtest_name_dba_4",
    formula: {
      gamma_nl: !0,
      gamma_be: !0,
      karwei_nl: !0
    }
  },
  "abtest_dba_3016-dba_7": {
    scope: "local",
    description: "Country field on top of address form",
    variants: e(["A-control", "B-top"]),
    domain_and_index: "abtest_name_dba_7",
    formula: {
      gamma_nl: !0,
      gamma_be: !0,
      karwei_nl: !0
    }
  },
  // MYACCOUNT -----------------------------------------------------------------
  // SHOPFRONT -----------------------------------------------------------------
  "abtest_button_shapes-ew_8": {
    scope: "local",
    description: "Cross domain test for new button shapes",
    variants: e(["A-control", "B-variant"]),
    domain_and_index: "abtest_name_ew_8",
    formula: {
      gamma_nl: !0,
      gamma_be: !0,
      karwei_nl: !0
    }
  }
};
export {
  l as experiments
};
