function o() {
  return "dataLayer" in window && window.dataLayer.some((e) => (e == null ? void 0 : e.event) === "gtm.load");
}
function i(e) {
  let n = 0;
  const t = window.setInterval(() => {
    if (n += 1, o()) {
      window.clearInterval(t), e();
      return;
    }
    n >= 40 && window.clearInterval(t);
  }, 300);
}
async function u() {
  return o() ? Promise.resolve() : new Promise((e) => {
    i(e);
  });
}
const r = [];
let a = !1;
typeof window < "u" && window.addEventListener(
  "DOMContentLoaded",
  () => {
    a = !0;
    for (const e of r)
      d(e);
  },
  { once: !0 }
);
function d(e) {
  if (!a) {
    r.push(e);
    return;
  }
  window.dispatchEvent(
    new CustomEvent(
      e.type,
      "data" in e ? { detail: e.data } : void 0
    )
  );
}
export {
  d as dispatchAdobeEvent,
  i as setupPolling,
  u as waitForGALoad
};
