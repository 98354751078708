import { jsxs as s, jsx as e } from "react/jsx-runtime";
import { media as n } from "@intergamma/theme";
import * as i from "@radix-ui/react-popover";
import d, { keyframes as o } from "styled-components";
import { DropdownRoot as p } from "./DropdownRoot.js";
function A({
  trigger: t,
  children: a,
  align: r = "center",
  ...m
}) {
  return /* @__PURE__ */ s(p, { ...m, children: [
    t,
    /* @__PURE__ */ e(y, { align: r, children: a })
  ] });
}
const c = o`
  from { opacity: 0; transform: translateY(2px); }
  to { opacity: 1; transform: translateY(0); }
  `, f = o`
  from { opacity: 0; transform: translateX(-2px); }
  to { opacity: 1; transform: translateX(0); }
`, l = o`
  from { opacity: 0; transform: translateY(-2px); }
  to { opacity: 1; transform: translateY(0); }
`, x = o`
  from { opacity: 0; transform: translateX(2px); }
  to { opacity: 1; transform: translateX(0); }
`, h = d(i.Content)`
  border-radius: 0.8rem;
  width: 100vw;
  margin: 0.625rem 0;

  background-color: ${({ theme: t }) => t.colors.white};
  border-radius: 0.8rem;

  box-shadow:
    hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;

  @media (prefers-reduced-motion: no-preference) {
    animation-duration: 300ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    will-change: transform, opacity;

    &[data-state="open"] {
      &[data-side="top"] {
        animation-name: ${l};
      }
      &[data-side="right"] {
        animation-name: ${x};
      }
      &[data-side="bottom"] {
        animation-name: ${c};
      }
      &[data-side="left"] {
        animation-name: ${f};
      }
    }
  }

  ${n.md`
    max-width: 730px;
  `};

  ${n.lg`
    max-width: 960px;
  `};

  ${n.xl`
    max-width: 1168px;
  `};
`;
function w({ children: t, container: a, ...r }) {
  return /* @__PURE__ */ e(i.Portal, { container: a, children: /* @__PURE__ */ e(h, { ...r, children: t }) });
}
const y = w;
export {
  A as Dropdown,
  y as DropdownContent
};
