import { fetchAuthSession as o } from "aws-amplify/auth";
import e from "js-cookie";
const a = "IGAppAuth";
async function i() {
  var t;
  const r = e.get(a);
  if (r)
    return r;
  try {
    const { tokens: n } = await o();
    return ((t = n == null ? void 0 : n.idToken) == null ? void 0 : t.toString()) ?? null;
  } catch {
    return null;
  }
}
const u = async (r) => {
  const t = await i();
  return {
    ...t && { Authorization: `Bearer ${t}` },
    ...r
  };
};
export {
  a as I,
  u as a,
  i as g
};
