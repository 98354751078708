import type { Brands } from "./Brands";

export interface ContentfulConfig {
  space: string;
  accessToken: string;
  entries: {
    NewsletterSubscribe: string;
    NewsletterVerificationSuccess: string;
    OrderStatusPage: string;
    LoginPageContent: string;
    LoyaltyToDiscount: string;
    ContactPage: string;
  };
}

const gammaNlContentFulConfig: ContentfulConfig = {
  space: "7k5qhlbm8iou",
  accessToken:
    "264fa5643e4db3af05892ce1cec30e1880b98e082f9e0e3074590b6d67c508a7",
  entries: {
    NewsletterSubscribe: "3GAQ4rjkZWegsimWMmUkAo",
    NewsletterVerificationSuccess: "5ahXRR3hjaWcqG8oMiC6ik",
    OrderStatusPage: "7AghV7CrGuLTjbb8kxlMT8",
    LoginPageContent: "3lKO7zbAKLz8R5j3535CSn",
    LoyaltyToDiscount: "71VSYgQmbM4tB15ZqgRuhB",
    ContactPage: "7dbbYAEEmwt9GbBL8gViGC",
  },
};

const gammaBeContentFulConfig: ContentfulConfig = {
  space: "2oqwvxysf41a",
  accessToken:
    "bdbdeba23f7ce4133a995c715a38bb2e161163a579cb925a27a2d3f4e44c9e76",
  entries: {
    NewsletterSubscribe: "3pXCo2Iz3W4qgOk4GqWSeQ",
    NewsletterVerificationSuccess: "4T6QSLuna0gmAuOwWCcI8m",
    OrderStatusPage: "5k7iP67tjR1su2VuUagV9N",
    LoginPageContent: "4MY5AqGWgMwePzo7RmEoMX",
    LoyaltyToDiscount: "7BE2Cjw920AOUu6ivcFW8G",
    ContactPage: "2fWDf5qDROWSm5j3ujwL9M",
  },
};

const karweiNlContentFulConfig: ContentfulConfig = {
  space: "ufyps4xa8qu5",
  accessToken:
    "2441ab9b85cbb65b61520dcf6c4948ef4f7e07619837f16aba5be8096a85c2b7",
  entries: {
    NewsletterSubscribe: "lRWGXtVPBmMIOSEMIcUuI",
    NewsletterVerificationSuccess: "44VmmThbaMue8K64UmOuKG",
    OrderStatusPage: "2TvTjQtS5Ro5ff0drvl9fZ",
    LoginPageContent: "2jzZG091tP2uFMOCYICinn",
    LoyaltyToDiscount: "65QseqIQChIJWE06iWe9cd",
    ContactPage: "27WIchbLJB08PLdsepdWnN",
  },
};

export function getContentfulConfig(brand: Brands) {
  switch (brand) {
    case "gamma_nl":
      return gammaNlContentFulConfig;
    case "gamma_be":
      return gammaBeContentFulConfig;
    case "karwei_nl":
      return karweiNlContentFulConfig;
    default:
      return gammaNlContentFulConfig;
  }
}
