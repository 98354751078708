var f = Object.defineProperty;
var m = (e, r, n) => r in e ? f(e, r, { enumerable: !0, configurable: !0, writable: !0, value: n }) : e[r] = n;
var d = (e, r, n) => (m(e, typeof r != "symbol" ? r + "" : r, n), n);
const h = {
  isKiosk: !0,
  id: "Random ID",
  storeUID: "",
  startUrl: "/assortiment/k/behang",
  hasPinTerminal: !0,
  pinTerminalId: "P400Plus-275103114",
  store: "EERKU",
  version: "",
  storeDepartment: "Behang",
  screenSaverIdleTime: 3e5,
  // 5 minutes
  screenSaverVideoUrl: "https://videos.ctfassets.net/7k5qhlbm8iou/3tzHccUyiZocyPvBtsNoRb/7c8e35265b08dcbd9fd708b881e4a3cb/screensaver.mp4",
  // Video of gamma
  debugEnabledTime: null
};
function l(e) {
  return {
    ...h,
    ...e
  };
}
function u() {
  return !!(process.env.VITE_FORCE_KIOSK && process.env.VITE_FORCE_KIOSK === "true");
}
function i(e, r, n) {
  var a;
  if (!("chrome" in window) || !((a = chrome == null ? void 0 : chrome.runtime) != null && a.sendMessage))
    throw new Error("Chrome is not defined");
  try {
    return new Promise(
      (s, c) => (
        // eslint-disable-next-line no-promise-executor-return
        chrome.runtime.sendMessage(
          e,
          { key: r, payload: n },
          (t) => t ? "data" in t ? s(t.data) : "error" in t ? c(t.error) : c() : c()
        )
      )
    );
  } catch (s) {
    throw new Error(s);
  }
}
function o() {
  return process.env.VITE_KIOSK_EXTENSION_ID ? process.env.VITE_KIOSK_EXTENSION_ID : document.location.host.includes("acceptatie") ? "bfblbleokjnmiigkafjmpgkmdeojfhka" : "hmgofimfefbcoomlplagdbofhglfkkpl";
}
async function E() {
  if (u())
    return l();
  try {
    return await i(
      o(),
      "GET_KIOSK_DATA"
    );
  } catch {
    return {
      isKiosk: !1
    };
  }
}
async function K() {
  if (process.env.NODE_ENV === "development")
    return console.log(
      "Cache cleared in development mode. Faking clear cache function"
    ), !0;
  try {
    return await i(
      o(),
      "CLEAR_ALL_CACHE"
    ), !0;
  } catch {
    return !1;
  }
}
async function w() {
  var e;
  if (u() || typeof window < "u" && ((e = window == null ? void 0 : window.navigator) != null && e.userAgent.includes("IG-Kiosk")))
    return !0;
  try {
    return await i(
      o(),
      "VALIDATE_IS_KIOSK"
    );
  } catch {
    return !1;
  }
}
async function g() {
  const e = u() ? l() : await E();
  if (!(!e.isKiosk || !e.hasPinTerminal))
    return {
      terminalId: e.pinTerminalId,
      kioskId: `${e.store}-${e.storeDepartment}`
    };
}
class I extends Error {
  constructor(n, a) {
    super(n);
    d(this, "fieldName");
    this.fieldName = a;
  }
}
async function _(e) {
  try {
    return await i(
      o(),
      "SET_KIOSK_DATA",
      e
    );
  } catch (r) {
    throw "name" in r && "message" in r ? new I(r.message, r.name) : new Error("Something went wrong");
  }
}
async function T(e) {
  try {
    return (await i(
      o(),
      "VALIDATE_CREDENTIALS",
      e
    )).role;
  } catch {
    return null;
  }
}
export {
  I as SetKioskDataError,
  K as clearCache,
  o as getExtensionId,
  E as getKioskData,
  g as getKioskPaymentData,
  w as isKiosk,
  _ as setKioskData,
  T as validateCredentials
};
