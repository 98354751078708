import o from "axios";
import { a as l } from "./get-headers-Z-0oHdPd.js";
function u(n, s) {
  const a = n;
  return a.cancel = s, a;
}
const { CancelToken: d } = o;
function h(n, s) {
  return {
    async get(a, e) {
      const t = await l(e == null ? void 0 : e.headers), c = d.source(), { data: r } = await u(
        o.get(a, {
          ...s,
          ...e,
          baseURL: n,
          cancelToken: c.token,
          headers: t
        }),
        c.cancel
      );
      return r;
    },
    async post(a, e, t) {
      const c = await l(t == null ? void 0 : t.headers), r = d.source(), { data: k } = await u(
        o.post(a, e, {
          ...s,
          ...t,
          baseURL: n,
          cancelToken: r.token,
          headers: c
        }),
        r.cancel
      );
      return k;
    },
    async put(a, e, t) {
      const c = await l(t == null ? void 0 : t.headers), r = d.source(), { data: k } = await u(
        o.put(a, e, {
          ...s,
          ...t,
          baseURL: n,
          cancelToken: r.token,
          headers: c
        }),
        r.cancel
      );
      return k;
    },
    async delete(a, e) {
      const t = await l(e == null ? void 0 : e.headers), c = d.source(), { data: r } = await u(
        o.delete(a, {
          ...s,
          ...e,
          baseURL: n,
          cancelToken: c.token,
          headers: t
        }),
        c.cancel
      );
      return r;
    }
  };
}
export {
  h as c,
  u as w
};
