import { jsxs as n, jsx as t } from "react/jsx-runtime";
import * as r from "@radix-ui/react-dialog";
import i, { keyframes as a } from "styled-components";
import { DialogRoot as c } from "./DialogRoot.js";
function g({ trigger: o, children: e, ...s }) {
  return /* @__PURE__ */ n(c, { ...s, children: [
    o,
    /* @__PURE__ */ t(u, { children: e })
  ] });
}
function l({
  children: o,
  ...e
}) {
  return /* @__PURE__ */ n(r.Portal, { children: [
    /* @__PURE__ */ t(x, { ...e, children: o }),
    /* @__PURE__ */ t(f, {})
  ] });
}
const p = 350, d = a`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`, m = a`
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(.96);
    backdrop-filter: blur(0);
  }

  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
    backdrop-filter: blur(2px);
  }
`, f = i(r.Overlay)`
  // Based on theme.neutral700
  background-color: hsl(200deg 60% 12% / 75%);
  z-index: 9999;

  position: fixed;
  inset: 0;

  @media (prefers-reduced-motion: no-preference) {
    animation: ${d} ${p}ms cubic-bezier(0, 0, 0.2, 1);
  }
`, x = i(r.Content)`
  background-color: ${({ theme: o }) => o.colors.white};
  border-radius: 0.8rem;
  z-index: 99999;

  box-shadow:
    hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 450px;
  max-height: 85vh;

  @media (prefers-reduced-motion: no-preference) {
    animation: ${m} 150ms cubic-bezier(0, 0, 0.2, 1);
  }

  &:focus {
    outline: none;
  }
`, u = l;
export {
  g as Dialog,
  u as DialogContent,
  f as StyledDialogOverlay,
  p as duration,
  d as overlayShow
};
