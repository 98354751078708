import { jsx as r, jsxs as n, Fragment as i } from "react/jsx-runtime";
import { Close as t } from "@intergamma/icons";
import * as s from "@radix-ui/react-dialog";
import l from "styled-components";
function u({
  children: o,
  ...e
}) {
  return /* @__PURE__ */ r(m, { ...e, children: /* @__PURE__ */ n(i, { children: [
    /* @__PURE__ */ r(t, {}),
    o
  ] }) });
}
const m = l(s.Close)`
  border: none;
  position: absolute;
  right: 0;
  top: 0.25rem;
  outline: none;
  cursor: pointer;
  color: ${({ theme: o }) => o.colors.primary};

  &:focus {
    outline: none;
    box-shadow: none;
  }
`;
export {
  u as DialogCloseIcon
};
