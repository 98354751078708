import {
  DiscountResponse,
  PurchaseDetails,
  PurchaseDetailsResponse,
  PurchaseItem,
} from "../types/Purchase";

import { getPurchaseStatus, getPurchaseType } from "./mapPurchasesResponse";
import { getDateTime, getDuration, purchaseItemIsReviewable } from "./helpers";

import { mapTimeResponse } from "~/services/mappers/mapTimeResponse";
import { mapLocalDateResponse } from "~/services/mappers/mapLocalDateResponse";

export const getLoyaltyPointsDiscount = (
  discounts: DiscountResponse[],
): number =>
  discounts
    .filter((discount) => discount.id === "PUNTEN")
    .reduce((acc, discount) => acc + discount.value, 0);

export const getTotalDiscountAmount = (
  purchaseDetails: PurchaseDetailsResponse,
): number =>
  purchaseDetails.items.reduce(
    (totalDiscountAmount, item) =>
      totalDiscountAmount +
      item.discounts.reduce(
        (itemTotal, discount) => itemTotal + discount.value,
        0,
      ),
    0,
  );

export const mapPurchaseDetailsResponse = (
  purchaseDetails: PurchaseDetailsResponse,
): PurchaseDetails => {
  const purchaseType = getPurchaseType(purchaseDetails.type);
  const purchasedOnDate = mapLocalDateResponse(purchaseDetails.purchasedOn);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [year, month, day, hour = 0, minute = 0, second, millisecond] =
    purchaseDetails.purchasedOn;

  return {
    purchaseId: purchaseDetails.purchaseId,
    totalAmount: purchaseDetails.totalAmount,
    totalDiscountAmount: getTotalDiscountAmount(purchaseDetails),
    loyaltyPointsDiscount: getLoyaltyPointsDiscount(purchaseDetails.discounts),
    deliveryCosts: purchaseDetails.deliveryCosts ?? 0,
    type: purchaseType,
    purchasedOn: {
      date: purchasedOnDate,
      time: mapTimeResponse([hour, minute]),
    },
    store: purchaseDetails.store
      ? {
          name: purchaseDetails.store.name,
          address: purchaseDetails.store.address,
          id: purchaseDetails.store.uid,
        }
      : null,
    status: getPurchaseStatus(purchaseDetails.state, purchaseType),
    loyalty: purchaseDetails.loyalty,
    deliveryAddress: purchaseDetails.deliveryAddress,
    invoiceAddress: purchaseDetails.invoiceAddress,
    items: purchaseDetails.items.map((item): PurchaseItem => {
      const warrantyExpirationDate = item.warrantyExpirationDate
        ? mapLocalDateResponse(item.warrantyExpirationDate).toLocaleDateString(
            "nl-NL",
          )
        : null;

      const returnDuration = item.returnUntil
        ? getDuration(purchaseDetails.purchasedOn, item.returnUntil)
        : null;

      const delivery = item.deliveryInformation;

      let isDropshipment = false;

      // There is no backend logic to determine which order is a dropshipment order.
      // The current logic we use is to check if there is a delivery window of multiple days.
      if (delivery?.deliveryWindow?.start && delivery?.deliveryWindow?.end) {
        isDropshipment =
          getDuration(
            delivery.deliveryWindow.start,
            delivery.deliveryWindow.end,
          ) > 0;
      }

      const status = getPurchaseStatus(item.state, purchaseType);

      return {
        code: item.code,
        imageUrl: item.imageUrl,
        url: item.url,
        imageWidth: item.imageWidth,
        name: item.name,
        quantity: item.quantity,
        subtotalAmount: item.subtotalAmount,
        status,
        cancellable: item.cancellable,
        manuals: item.manuals,
        configuredProduct: item.configuredProduct,
        warrantyExpirationDate,
        isReviewable: purchaseItemIsReviewable(status.state),
        returnDuration:
          returnDuration && returnDuration > 0 ? returnDuration : null,
        delivery: delivery?.deliveryWindow
          ? {
              start:
                delivery.deliveryWindow.start &&
                getDateTime(delivery.deliveryWindow.start),

              end:
                delivery.deliveryWindow.end &&
                getDateTime(delivery.deliveryWindow.end),
            }
          : null,
        discountAmount: item.discounts.reduce(
          (acc, discount) => acc + discount.value,
          0,
        ),
        deposit: item.deposit,
        pickupCode: item.pickupCode,
        isDropshipment,
        tracking: item.tracking,
      };
    }),
    invoices: purchaseDetails.invoices,
    payments: purchaseDetails.payments,
    totalQuantity: purchaseDetails.items.reduce(
      (total, item) => total + (item.quantity ?? 1),
      0,
    ),
  };
};
