import type { BaseConfig } from "./BaseConfig";

export interface UrlConfig {
  nl: UrlsFromConfig;
  fr?: UrlsFromConfig;
}

export interface UrlsFromConfig {
  loyaltyProgram: string;
  delivery: string;
  returnRequirements: string;
  returnCosts: string;
  returnFaqs: string;
  returnToShopFaqs: string;
  changeOrderOrCancel: string;
  privacy: string;
  customerService: string;
  requirementsLoyalty: string;
  warranty: string;
}

export function getUrlConfig(baseConfig: BaseConfig): UrlConfig {
  const gammaNlUrlConfig: UrlConfig = {
    nl: {
      loyaltyProgram: `${baseConfig.SHOPFRONT_URL}/voordeelpas`,
      returnRequirements: `${baseConfig.SHOPFRONT_URL}/klantenservice/faq/retour-en-terugbetalen#faq-1-5`,
      returnCosts: `${baseConfig.SHOPFRONT_URL}/klantenservice/faq/retour-en-terugbetalen#faq-1-0`,
      returnFaqs: `${baseConfig.SHOPFRONT_URL}/klantenservice/faq/retour-en-terugbetalen`,
      returnToShopFaqs: `${baseConfig.SHOPFRONT_URL}/klantenservice/faq/retourneren#faq-1-4`,
      changeOrderOrCancel: `${baseConfig.SHOPFRONT_URL}/klantenservice/faq/bestellen-en-betalen`,
      delivery: `${baseConfig.SHOPFRONT_URL}/klantenservice/faq/bezorgen`,
      privacy: `${baseConfig.SHOPFRONT_URL}/klantenservice/veiligheid-privacy`,
      customerService: `${baseConfig.SHOPFRONT_URL}/klantenservice`,
      requirementsLoyalty: `${baseConfig.SHOPFRONT_URL}/klantenservice/algemene-voorwaarden/voordeelpas`,
      warranty: `${baseConfig.SHOPFRONT_URL}/klantenservice/faq/garantie`,
    },
  };

  const gammaBeUrlConfig: UrlConfig = {
    nl: {
      loyaltyProgram: `${baseConfig.SHOPFRONT_URL}/nl/klanteninformatie/gammapluskaart`,
      returnRequirements: `${baseConfig.SHOPFRONT_URL}/nl/klanteninformatie`,
      returnCosts: `${baseConfig.SHOPFRONT_URL}/nl/klanteninformatie`,
      returnFaqs: `${baseConfig.SHOPFRONT_URL}/nl/klanteninformatie`,
      returnToShopFaqs: `${baseConfig.SHOPFRONT_URL}/nl/klanteninformatie/ruilen-retourneren/omruilen-winkel`,
      changeOrderOrCancel: `${baseConfig.SHOPFRONT_URL}/nl/klanteninformatie/faq/bestellen-en-betalen`,
      delivery: `${baseConfig.SHOPFRONT_URL}/nl/klanteninformatie/bestellen-levering/levering`,
      privacy: `${baseConfig.SHOPFRONT_URL}/nl/klanteninformatie/veiligheid-privacy`,
      customerService: `${baseConfig.SHOPFRONT_URL}/nl/klanteninformatie`,
      requirementsLoyalty: `${baseConfig.SHOPFRONT_URL}/nl/klanteninformatie/gammapluskaart?hp-gammaplus`,
      warranty: `${baseConfig.SHOPFRONT_URL}/nl/klanteninformatie/ruilen-retourneren/garantie`,
    },
    fr: {
      loyaltyProgram: `${baseConfig.SHOPFRONT_URL}/fr/infos-consommateur/carte-gammaplus`,
      returnRequirements: `${baseConfig.SHOPFRONT_URL}/fr/infos-consommateur`,
      returnCosts: `${baseConfig.SHOPFRONT_URL}/fr/infos-consommateur`,
      returnFaqs: `${baseConfig.SHOPFRONT_URL}/fr/infos-consommateur`,
      returnToShopFaqs: `${baseConfig.SHOPFRONT_URL}/fr/infos-consommateur/echanges-retours/echange-magasin`,
      changeOrderOrCancel: `${baseConfig.SHOPFRONT_URL}/fr/infos-consommateur`,
      delivery: `${baseConfig.SHOPFRONT_URL}/fr/infos-consommateur/commandes-livraisons/livraison`,
      privacy: `${baseConfig.SHOPFRONT_URL}/fr/infos-consommateur/securite-vie-privee`,
      customerService: `${baseConfig.SHOPFRONT_URL}/fr/infos-consommateur`,
      requirementsLoyalty: `${baseConfig.SHOPFRONT_URL}/fr/infos-consommateur/carte-gammaplus`,
      warranty: `${baseConfig.SHOPFRONT_URL}/fr/infos-consommateur/echanges-retours/garantie`,
    },
  };

  const karweiNlUrlConfig: UrlConfig = {
    nl: {
      loyaltyProgram: `${baseConfig.SHOPFRONT_URL}/clubkarwei`,
      returnRequirements: `${baseConfig.SHOPFRONT_URL}/klantenservice/faq/retour-en-terugbetalen#faq-1-5`,
      returnCosts: `${baseConfig.SHOPFRONT_URL}/klantenservice/faq/retour-en-terugbetalen#faq-1-0`,
      returnFaqs: `${baseConfig.SHOPFRONT_URL}/klantenservice/faq/retour-en-terugbetalen`,
      returnToShopFaqs: `${baseConfig.SHOPFRONT_URL}/klantenservice/faq/retourneren#faq-1-4`,
      changeOrderOrCancel: `${baseConfig.SHOPFRONT_URL}/klantenservice/faq/bestellen-en-betalen`,
      delivery: `${baseConfig.SHOPFRONT_URL}/klantenservice/faq/bezorgen`,
      privacy: `${baseConfig.SHOPFRONT_URL}/klantenservice/voorwaarden-veiligheid`,
      customerService: `${baseConfig.SHOPFRONT_URL}/klantenservice`,
      requirementsLoyalty: `${baseConfig.SHOPFRONT_URL}/klantenservice/voorwaarden-veiligheid/algemene-voorwaarden/club-karwei`,
      warranty: `${baseConfig.SHOPFRONT_URL}/nl/klantenservice/faq/garantie`,
    },
  };

  switch (baseConfig.fullBrand) {
    case "gamma_nl":
      return gammaNlUrlConfig;
    case "gamma_be":
      return gammaBeUrlConfig;
    case "karwei_nl":
      return karweiNlUrlConfig;
    default:
      return gammaNlUrlConfig;
  }
}
