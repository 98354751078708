import * as o from "@radix-ui/react-dialog";
import r from "styled-components";
const e = r(o.Title)`
  margin: 0 0 1rem;
  font-weight: 500;
  color: ${({ theme: i }) => i.colors.primary};
  font-size: 1.4rem;
  line-height: 1;
`;
export {
  e as DialogTitle
};
