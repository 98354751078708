import * as o from "@radix-ui/react-dialog";
import r from "styled-components";
const t = r(o.Description)`
  color: ${({ theme: i }) => i.colors.black};
  font-size: 1rem;
  line-height: 1.4;
  margin: 1rem 0;
`;
export {
  t as DialogDescription
};
