import { datadogLogs as o, HandlerType as D, StatusType as m } from "@datadog/browser-logs";
import "@intergamma/kiosk-util";
var l = /* @__PURE__ */ ((r) => (r.App = "app", r.Browser = "browser", r.Kiosk = "kiosk", r))(l || {});
const g = (r, t) => (a, n = {}) => {
  try {
    const s = A(
      n,
      P
    );
    r[t](a, s);
  } catch (s) {
    console.error("datadog-logging-error", s);
  }
};
function L(r) {
  try {
    const t = k(r);
    o.setGlobalContextProperty("requestMode", t);
  } catch (t) {
    console.error(
      `Failed to add the requestMode to the global DataDog log context: ${t}`
    );
  }
}
function R({
  brand: r,
  skin: t,
  version: a,
  clientKey: n,
  env: s,
  serviceName: b,
  logDestination: u,
  logLevel: c,
  preferredStore: C
}) {
  const d = {
    brand: r,
    formula: t,
    preferredStore: C,
    version: a,
    module: "myaccount"
  };
  o.init({
    beforeSend: (e) => {
      var i, p, f, w, E, h;
      return (p = (i = e.error) == null ? void 0 : i.stack) != null && p.includes("TranslatedError") || (w = (f = e.error) == null ? void 0 : f.stack) != null && w.includes("AxiosError") || (h = (E = e.error) == null ? void 0 : E.stack) != null && h.includes("csp_violation") || e.http && e.http.status_code === 0 ? !1 : (e.view.url = e.view.url.replace(/hash=[^&]*/, "hash=REDACTED"), e.view.url = e.view.url.replace(
        /signature=[^&]*/,
        "signature=REDACTED"
      ), e.view.url = e.view.url.replace(/email=[^&]*/, "email=REDACTED"), e.view.url = e.view.url.replace(
        /postalCode=[^&]*/,
        "postalCode=REDACTED"
      ), !0);
    },
    clientToken: n,
    env: s,
    forwardErrorsToLogs: !0,
    sessionSampleRate: 100,
    service: b,
    site: "datadoghq.eu",
    trackSessionAcrossSubdomains: !0,
    version: a
  }), Object.entries(d).forEach(([e, i]) => {
    o.setGlobalContextProperty(e, i);
  });
  function O() {
    return u && D.hasOwnProperty(u) ? u : (console.warn(
      "Failed to read DataDog log destination config. Defaulting to: http"
    ), D.http);
  }
  function y() {
    return c && m.hasOwnProperty(c) ? c : (console.warn(
      "Failed to read DataDog log level config. Defaulting to: info"
    ), m.info);
  }
  o.logger.setLevel(y()), o.logger.setHandler(O()), o.setGlobalContext(d);
}
const S = {
  error: g(o.logger, "error"),
  info: g(o.logger, "info"),
  warn: g(o.logger, "warn")
};
function A(r, t) {
  return Object.fromEntries(
    Object.entries(r).map(([a, n]) => [
      a,
      t(n)
    ])
  );
}
function P(r) {
  return typeof r == "object" && Object.getOwnPropertyNames(r).length > 0 ? T(r) : r;
}
function T(r) {
  return JSON.parse(
    JSON.stringify(r, Object.getOwnPropertyNames(r))
  );
}
function k(r) {
  switch (r) {
    case l.App:
      return "APP_MODE";
    case l.Browser:
      return "DEFAULT_MODE";
    case l.Kiosk:
      return "KIOSK_MODE";
    default:
      throw new Error(
        `A device type was provided that is not known as a request mode: ${r}`
      );
  }
}
function q(r, t) {
  o.setGlobalContextProperty(r, t);
}
export {
  L as a,
  R as i,
  S as l,
  q as s
};
