import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export function useAlternateUrls() {
  const { pathname } = useLocation();
  const {
    t,
    i18n: { language },
  } = useTranslation("urls");

  if (language === "nl-BE") {
    const alternateURL: string = NLtoFRMapping.get(pathname) ?? t("/myaccount");

    return {
      nl: pathname,
      fr: alternateURL,
    };
  }

  const alternateURL: string = FRToNLMapping.get(pathname) ?? t("/myaccount");

  return {
    nl: alternateURL,
    fr: pathname,
  };
}

const FRToNLMapping = new Map([
  ["/fr/", "/nl/"],
  ["/fr/se-connecter", "/nl/inloggen"],
  ["/fr/se-deconnecter", "/nl/uitloggen"],
  ["/fr/creer-un-compte", "/nl/account-aanmaken"],
  ["/fr/creer-un-compte-confirme", "/nl/account-aanmaken-bevestigd"],
  ["/fr/mes-donnees", "/nl/mijn-gegevens"],
  [
    "/fr/mes-donnees/mes-donnes-personnelles",
    "/nl/mijn-gegevens/persoonlijke-gegevens",
  ],
  [
    "/fr/mes-donnees/changer-le-mot-de-passe",
    "/nl/mijn-gegevens/wachtwoord-wijzigen",
  ],
  [
    "/fr/mes-donnees/changer-ladresse-mail",
    "/nl/mijn-gegevens/e-mailadres-wijzigen",
  ],
  ["/fr/mes-donnees/lettre-info", "/nl/mijn-gegevens/nieuwsbrief"],
  ["/fr/mes-donnees/carte-gamma-plus", "/nl/mijn-gegevens/gamma-plus-kaart"],
  ["/fr/carte-cadeau", "/nl/cadeaukaart"],
  [
    "/fr/demande-de-suppression-de-mon-compte",
    "/nl/verzoek-tot-verwijderen-account",
  ],
  ["/fr/mes-commandes", "/nl/mijn-bestellingen"],
  ["/fr/etat-de-la-commande", "/nl/bestelstatus-opvragen"],
  ["/fr/inscription-retour", "/nl/aanmelden-retour"],
  ["/fr/demander-nouveau-mot-de-passe", "/nl/nieuw-wachtwoord-aanvragen"],
  ["/fr/carte-gamma-plus/registre", "/nl/gamma-plus-kaart/registreren"],
  ["/fr/carte-gamma-plus/activer", "/nl/gamma-plus-kaart/activeer"],
  ["/fr/inscrire-lettre-info", "/nl/aanmelden-nieuwsbrief"],
  ["/fr/recus", "/nl/kassabonnen"],
  ["/fr/campagne", "/nl/campagne"],
  ["/fr/service-en-contact/contact", "/nl/service-en-contact/contact"],
  ["/fr/GAMMAplus-liste-transactions", "/nl/loyalty-transactie-overzicht"],
]);

const NLtoFRMapping = new Map(
  Array.from(FRToNLMapping, (entry) => [entry[1], entry[0]]),
);
