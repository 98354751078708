import { useCallback, useMemo, useState } from "react";

import { useConfig } from "@intergamma/config";
import { Category, createGetAssortimentCategories } from "@intergamma/header";

import { useAxiosQuery } from "./useAxiosQuery";

export const QUERY_KEY = "base-navigation";

export function useCategoriesQuery() {
  const [activeCategory, setActiveCategory] = useState<Category | undefined>(
    undefined,
  );

  const config = useConfig();
  const getAssortimentCategories = useMemo(
    () => createGetAssortimentCategories(config),
    [config],
  );

  const getCurrentCategories = useCallback(async () => {
    if (!activeCategory) {
      return [];
    }

    return getAssortimentCategories(activeCategory.dataUrl);
  }, [activeCategory, getAssortimentCategories]);

  const { data: categories } = useAxiosQuery(
    [QUERY_KEY, activeCategory?.uid],
    getCurrentCategories,
    {
      suspense: false,
      useErrorBoundary: false,
      keepPreviousData: true,
    },
  );

  function onSelect(category: Category) {
    setActiveCategory(category);
  }

  return { categories, onSelect };
}
