import * as r from "@radix-ui/react-dialog";
import o from "styled-components";
const g = o(r.Trigger)`
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 0;
  margin: 0;
  height: auto;

  &:focus {
    box-shadow: none;
  }
`, e = r.Trigger;
export {
  g as DialogTrigger,
  e as UnstyledDialogTrigger
};
