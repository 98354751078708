import * as r from "@radix-ui/react-popover";
import o from "styled-components";
const i = o(r.Trigger)`
  border: none;
  background: transparent;
  cursor: pointer;
  height: auto;
  padding: 0;
  margin: 0;
`;
export {
  i as DropdownTrigger
};
