import { jsxs as h, jsx as i, Fragment as D } from "react/jsx-runtime";
import { useConfig as b } from "@intergamma/config";
import { dispatchAdobeEvent as P } from "@intergamma/adobe-tracking";
import { useTranslation as p } from "react-i18next";
import { useMemo as T, useState as z } from "react";
import { X as L } from "react-feather";
import { connectAutoComplete as N, connectStateResults as H, InstantSearch as O, Configure as j } from "react-instantsearch-dom";
import d from "styled-components";
import { Downshift as _, DownshiftMenu as K, DownshiftItems as M, DownshiftItem as U } from "@intergamma/downshift";
import { media as y } from "@intergamma/theme";
import F from "algoliasearch";
import J from "js-cookie";
const Q = () => {
  if (typeof localStorage > "u")
    return;
  const e = localStorage.getItem("queryList");
  if (e)
    try {
      return JSON.parse(e);
    } catch {
      return;
    }
};
function X(e, t) {
  const { t: r } = p("ig-search-bar");
  if (e.length !== 0)
    return t;
  const o = Q(), s = [];
  if (o && o.length > 0)
    for (let n = 0; n < o.length; n += 1) {
      const l = {
        query: o[n]
      };
      n === 0 && (l.typeLabel = r("RecentQueries")), s.push(l);
    }
  return s;
}
const S = d.input`
  && {
    width: 100%;
    height: ${(e) => e.theme.karwei ? 40 : 46}px;
    box-sizing: border-box;
    background: ${(e) => e.theme.inputBackgroundColor};
    border: 1px solid ${(e) => e.theme.formInputBorderColor};
    border-radius: ${(e) => e.theme.karwei ? 3 : 99}px !important;
    padding-left: ${(e) => e.theme.karwei ? 10 : 17}px;
    padding-right: ${(e) => e.theme.karwei ? 40 : 44}px;
    font-size: 0.875em;
    color: ${(e) => e.theme.formInputTextColor};
    transition: all 0.2s;
    -webkit-appearance: none;

    &:focus {
      outline: 0;
      border-color: ${(e) => e.theme.linkColor};
      box-shadow: 0 0 0 3px ${(e) => e.theme.linkHighlightColor};
      border-radius: ${(e) => e.theme.karwei ? 3 : 99}px;
    }

    ${y.md`
      font-size: 1em;

      &,
      & + div {
        position: absolute;
        right: 0;
        transition: width 100ms;
      }

      &:focus,
      &:focus + div {
        width: 485px;
      }
    `}

    ${y.xl`
      &:focus,
      &:focus + div {
        width: 600px;
      }
    `}

    & + div {
      top: ${(e) => e.theme.karwei ? 40 : 46}px;
      li {
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    // Hide clear button for search inputs
    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      appearance: none;
    }
  }
`;
function E({ query: e, item: t }) {
  const { t: r } = p("ig-search-bar");
  if (!t.query)
    return null;
  const o = t.query.substr(0, e.length);
  return /* @__PURE__ */ h("div", { children: [
    String(o).valueOf() === String(e).valueOf() ? /* @__PURE__ */ i("strong", { children: e }) : o,
    t.query.substr(e.length),
    " ",
    t.category && /* @__PURE__ */ h("span", { className: "query-category", children: [
      r("In"),
      " ",
      t.category
    ] }),
    t.typeLabel && /* @__PURE__ */ i("span", { className: "suggestion-type", children: t.typeLabel })
  ] });
}
function Y({
  refine: e,
  hits: t,
  searchResults: r,
  value: o,
  setValue: s,
  onSelect: n,
  downshiftEnvironment: l
}) {
  const { isMobile: m } = b(), { t: u } = p("ig-search-bar"), c = (r == null ? void 0 : r.query) ?? "", x = X(c, t);
  return /* @__PURE__ */ i(
    _,
    {
      environment: l,
      selectedItem: { query: o },
      itemToString: (a) => typeof a == "object" ? (a == null ? void 0 : a.query) || "" : a || "",
      onSelect: (a) => {
        a && a.query && n(a);
      },
      onInputValueChange: (a) => {
        s(a);
      },
      children: ({
        getInputProps: a,
        getItemProps: I,
        getMenuProps: k,
        highlightedIndex: $,
        isOpen: q,
        openMenu: A
      }) => {
        const R = a({
          onFocus() {
            A();
          },
          onChange(g) {
            e(g.currentTarget.value);
          },
          autoComplete: "off",
          autoCapitalize: "off",
          autoCorrect: "off"
        });
        return /* @__PURE__ */ h(D, { children: [
          /* @__PURE__ */ i(
            S,
            {
              type: "search",
              name: "text",
              placeholder: u(m ? "Search" : "SearchByKeyword"),
              required: !0,
              ...R
            }
          ),
          /* @__PURE__ */ i(
            K,
            {
              ...k(),
              $isOpen: q && x.length > 0,
              $pill: !0,
              role: "form",
              "aria-label": u("Suggestions"),
              children: /* @__PURE__ */ i(M, { role: "listbox", "aria-label": u("Suggestions"), children: x.map((g, f) => {
                const B = I({
                  item: g,
                  index: f
                });
                return g.showIndex = f + 1, /* @__PURE__ */ i(
                  U,
                  {
                    $highlighted: f === $,
                    ...B,
                    role: "option",
                    children: /* @__PURE__ */ i(E, { query: c, item: g })
                  },
                  f
                );
              }) })
            }
          )
        ] });
      }
    }
  );
}
const G = N(
  H(Y)
), v = d.form`
  position: relative;
  width: 100%;
  height: ${(e) => e.theme.karwei ? 40 : 46}px;

  svg {
    position: absolute;
    top: 11px;
    left: 11px;
    z-index: 1;
  }
  svg.shadow {
    top: 12px;
    z-index: 0;
    opacity: 0.3;
  }
`;
function C() {
  const { t: e } = p("ig-search-bar");
  return /* @__PURE__ */ h(W, { type: "submit", children: [
    /* @__PURE__ */ i(Z, { children: e("Search") }),
    /* @__PURE__ */ i(
      "svg",
      {
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 250.3 250.3",
        width: "20",
        height: "20",
        focusable: "false",
        children: /* @__PURE__ */ i("path", { d: w, fill: "#fff", fillRule: "evenodd", clipRule: "evenodd" })
      }
    ),
    /* @__PURE__ */ i(
      "svg",
      {
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 250.3 250.3",
        width: "20",
        height: "20",
        className: "shadow",
        focusable: "false",
        children: /* @__PURE__ */ i(
          "path",
          {
            d: w,
            fill: "#000",
            fillRule: "evenodd",
            clipRule: "evenodd",
            className: "shadow"
          }
        )
      }
    )
  ] });
}
const W = d.button`
  position: absolute;
  top: ${(e) => e.theme.karwei ? 0 : 3}px;
  right: ${(e) => e.theme.karwei ? 0 : 3}px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  background-color: ${(e) => e.theme.karwei ? e.theme.brandColor : e.theme.buttonColor};
  border: none;
  border-radius: ${(e) => e.theme.karwei ? "0 3px 3px 0" : "99px"};
  color: #fff;
  transition: all 0.2s;
  z-index: 500;
  &:focus {
    outline: 0;
    background: ${(e) => e.theme.buttonFocusColor};
    box-shadow: 0 0 0 3px ${(e) => e.theme.buttonHighlightColor};
  }
`, Z = d.span`
  position: absolute;
  left: -9999px;
`, w = "M244.2 214.6l-54.4-54.4-1-.7a103 103 0 1 0-29.4 29.4l.8 1 54.4 54.3a21 21 0 0 0 29.6-29.6zm-141.3-44.5a67.2 67.2 0 1 1 0-134.4 67.2 67.2 0 0 1 0 134.4z";
function V() {
  const e = b();
  return T(() => {
    const t = F(
      e.algoliaApplicationId,
      e.suggestionApiKey
    );
    return {
      ...t,
      search(o) {
        return o.every(({ params: s }) => !s.query) ? Promise.resolve({
          results: o.map(() => ({
            hits: [],
            nbHits: 0,
            nbPages: 0,
            page: 0,
            processingTimeMS: 0
          }))
        }) : t.search(o);
      }
    };
  }, [e.algoliaApplicationId, e.suggestionApiKey]);
}
function ee(e, t, r, o) {
  e != null && e.objectID && window.fetch("https://insights.algolia.io/1/events", {
    method: "POST",
    headers: {
      "X-Algolia-Application-Id": t,
      "X-Algolia-API-Key": r,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      events: [
        {
          eventType: "click",
          eventName: "Search Suggestion Clicked",
          index: o,
          userToken: J.get("search_user_token"),
          timestamp: Date.now(),
          objectIDs: [e.objectID],
          queryID: e.queryID,
          positions: [e.showIndex]
        }
      ]
    })
  });
}
function te(e) {
  if (!e || e === "")
    return;
  const t = "queryList", r = localStorage.getItem(t);
  let o;
  try {
    r && (o = JSON.parse(r)), o || (o = []), o.includes(e) || (o.unshift(e), o.length > 4 && o.pop()), localStorage.setItem(t, JSON.stringify(o));
  } catch {
  }
}
function oe() {
  if (typeof window > "u")
    return "";
  const e = /[?&]text=([^&#]*)/.exec(window.location.search);
  return e ? decodeURIComponent(e[1].replace(/\+/g, " ")) : "";
}
function ie(e) {
  if (typeof e == "string")
    return `?text=${encodeURIComponent(e)}`;
  let t = `?text=${encodeURIComponent(e.query)}`;
  return e.category && (t += `&f_categories=${encodeURIComponent(e.category)}`), t;
}
function re({
  onSubmit: e,
  algoliaSuggestionIndex: t,
  downshiftEnvironment: r
}) {
  const { t: o } = p("ig-search-bar"), [s, n] = z(oe);
  function l() {
    n("");
  }
  const m = V();
  function u(c) {
    typeof c != "string" && te(c.query), e(c);
  }
  return /* @__PURE__ */ h(
    v,
    {
      method: "get",
      action: o("/search"),
      onSubmit: (c) => {
        c.preventDefault(), u(s);
      },
      noValidate: !0,
      children: [
        /* @__PURE__ */ h(
          O,
          {
            searchClient: m,
            indexName: t,
            children: [
              /* @__PURE__ */ i(j, { clickAnalytics: !0, hitsPerPage: 4 }),
              /* @__PURE__ */ i(
                G,
                {
                  value: s,
                  onSelect: (c) => u(c),
                  setValue: n,
                  downshiftEnvironment: r
                }
              )
            ]
          }
        ),
        s && /* @__PURE__ */ i(
          ne,
          {
            onClick: () => l(),
            title: o("ClearResults"),
            "aria-label": o("ClearResults"),
            type: "reset",
            children: /* @__PURE__ */ i(ae, { "aria-hidden": "true", focusable: "false" })
          }
        ),
        /* @__PURE__ */ i(C, {})
      ]
    }
  );
}
const ne = d.button`
  cursor: pointer;
  position: absolute;
  right: 45px;
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  top: 50%;
  z-index: 500;
  transform: translateY(-50%);
  text-align: center;
  border: 0;
  padding: 0;
  display: grid;
  place-items: center;
  svg {
    position: static;
    margin-top: 2px;
  }
  &:hover {
    box-shadow: 0 3px 7px rgb(0 0 0 / 25%);
  }
  &:focus {
    border-radius: 12px;
    box-shadow: 0 0 0 3px ${(e) => e.theme.brandColorHighlight};
  }
`, ae = d(L)`
  stroke-width: 3px;
  width: 20px;
  height: 20px;
  color: ${(e) => e.theme.iconColor};
`;
function ye({
  downshiftEnvironment: e
}) {
  const t = b(), { t: r } = p("ig-search-bar"), o = t.algoliaSuggestionIndex ? t.algoliaSuggestionIndex : r("algoliaSuggestionIndex");
  function s(n) {
    typeof n != "string" && (P({
      type: "click_search_suggestions",
      data: {
        user_selected_value: n.query,
        position: n.showIndex
      }
    }), ee(
      n,
      t.algoliaApplicationId,
      t.suggestionApiKey,
      o
    ));
    const l = `${r("/search")}${ie(n)}`;
    window.location.href = l;
  }
  return /* @__PURE__ */ i(
    re,
    {
      onSubmit: (n) => s(n),
      algoliaSuggestionIndex: o,
      downshiftEnvironment: e
    }
  );
}
function we() {
  const { t: e } = p("ig-search-bar");
  return /* @__PURE__ */ h(
    v,
    {
      method: "get",
      action: e("/search"),
      onSubmit: (t) => {
        t.preventDefault();
      },
      noValidate: !0,
      children: [
        /* @__PURE__ */ i(
          S,
          {
            type: "search",
            name: "text",
            autoComplete: "off",
            autoCapitalize: "off",
            autoCorrect: "off",
            required: !0
          }
        ),
        /* @__PURE__ */ i(C, {})
      ]
    }
  );
}
export {
  we as LoadingSearchBar,
  ye as MainSearchBar,
  re as SearchBar,
  te as addQueryResultToLocalStorage,
  oe as getQuerySearchInitialValue,
  ie as getSearchUrl,
  ee as sendResultToAlgolia
};
