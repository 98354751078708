import { jsx as i } from "react/jsx-runtime";
import s from "downshift";
import t, { keyframes as p, css as r } from "styled-components";
function x({
  children: o,
  ...n
}) {
  return /* @__PURE__ */ i(s, { ...n, children: (e) => /* @__PURE__ */ i(
    d,
    {
      ...e.getRootProps({ refKey: "ref" }),
      $isOpen: e.isOpen,
      children: o(e)
    }
  ) });
}
const a = p`
  from {
    opacity: 0;
    transform: translateY(0);
  }
  to {
    opacity: 1;
    transform: translateY(8px);
  }
`, d = t.div`
  position: relative;
  z-index: ${(o) => o.$isOpen ? 500 : "auto"};
`, b = t.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: ${(o) => o.$visible ? 0 : "auto"};
  background: #fff;
  opacity: ${(o) => o.$visible ? 0.7 : 0};
  transition: opacity 0.3s;
  z-index: 499;
`, c = t.div`
  position: absolute;
  left: ${(o) => o.$isOpen ? "auto" : "-9999px"};
  background: #fff;
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  border: 1px solid ${(o) => o.theme.buttonColor};
  border-radius: ${(o) => o.theme.borderRadius}px;
  background-color: ${(o) => o.theme.inputBackgroundColor};
  box-shadow: 0 0 0 3px ${(o) => o.theme.buttonHighlightColor};
  z-index: 501;

  ${(o) => o.$isOpen && r`
      animation: ${a} 0.3s forwards;
    `};

  ${(o) => o.$pill && r`
      padding: 3px;
      border-radius: 23px;

      li {
        padding: 10px 14px;
        border-radius: 20px;
        border: none;
      }
    `};
`, u = t.ul`
  margin: 0;
  padding: 17px 0 0 0;
  list-style: none;
  position: relative;
`, m = t.li`
  margin: 0;
  padding: 10px;
  background: ${(o) => o.$highlighted ? `${o.theme.buttonColor}16` : "transparent"};
  border-bottom: 1px solid ${(o) => o.theme.borderColor};
  cursor: pointer;
  position: relative;

  &:last-child {
    border-bottom: none;
  }

  .suggestion-type {
    position: absolute;
    right: 16px;
    font-size: 13px;
    color: ${(o) => o.theme.borderColor};
  }

  &:first-of-type .suggestion-type {
    top: -17px;
  }

  &.start-search-suggestions {
    margin-top: 10px;

    :before {
      content: "";
      position: absolute;
      width: calc(100% - 30px);
      height: 1px;
      border-top: solid 1px ${(o) => o.theme.dividerColor};
      top: -5px;
    }
  }

  .query-category {
    color: ${(o) => o.theme.linkColor};
    text-transform: lowercase;
    font-size: 13px;
  }
`;
export {
  x as Downshift,
  b as DownshiftBackdrop,
  m as DownshiftItem,
  u as DownshiftItems,
  c as DownshiftMenu,
  x as default
};
