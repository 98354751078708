import { lazy } from "react";

import { useTranslation } from "react-i18next";
import { RouteObject } from "react-router-dom";

import { DSLayout } from "~/layouts/DSLayout";

import { useBaseConfig } from "~/config/MyAccountConfigContext";

const GiftCardPage = lazy(() => import("~/pages/giftcard/GiftCardPage"));

export function useDesignSystemRoutes(): RouteObject[] {
  const { t } = useTranslation("urls");
  const baseConfig = useBaseConfig();

  return [
    {
      element: <DSLayout />,
      children: [
        {
          path: t("/giftcard"),
          element: <GiftCardPage />,
        },
      ].map((route) => {
        if (baseConfig.country !== "be") {
          return route;
        }

        return {
          ...route,
          path: route?.path?.substring(4),
        };
      }),
    },
  ];
}
