import t from "styled-components";
import { media as i } from "@intergamma/theme";
const m = t.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`, n = t.li`
  position: relative;
  margin-bottom: 10px;
  padding-left: 14px;
  line-height: 1.4em;

  &:last-child {
    margin-bottom: 0;
  }

  &::before {
    content: "›";
    position: absolute;
    left: 0;
  }

  ${i.md`
    margin-bottom: 3px;
  `};
`;
export {
  m as List,
  n as ListItem
};
