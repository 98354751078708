import { useAxiosQuery } from "~/features/shared/hooks/useAxiosQuery";
import { useBaseApiService } from "~/features/shared/ServicesContext";

export const QUERY_KEY = "session-data";

export function useSessionDataQuery() {
  const baseApiService = useBaseApiService();

  return useAxiosQuery([QUERY_KEY], baseApiService.getSessionData, {
    suspense: true,
  });
}
