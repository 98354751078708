import { jsxs as l, jsx as e } from "react/jsx-runtime";
import { Button as u } from "@intergamma/button";
import { useConfig as b } from "@intergamma/config";
import { LoyaltyCard as v } from "@intergamma/loyalty-card";
import { CheckMark as N } from "@intergamma/ui/icons";
import { getMobileOperatingSystem as k, MobileOperatingSystem as C } from "@intergamma/common";
import { useTranslation as x, Trans as L } from "react-i18next";
import { useNavigate as j } from "react-router-dom";
import { a as A } from "./get-headers-Z-0oHdPd.js";
function $({
  loyaltyCardNumber: i,
  close: c
}) {
  const { t, i18n: r } = x(["urls", "loyalty-benefits"]), { brand: d, MYACCOUNT_URL: m } = b(), p = j(), f = () => {
    c(), p(t("urls:/profile/loyaltycards"), {
      state: { showForm: !0 },
      replace: !0
    });
  }, g = `${m}/api/app/loyalty/cards/apple-wallet-pass`, s = k() === C.IOS, h = async (y) => {
    const a = y.currentTarget;
    if (!(a != null && a.href))
      try {
        const o = await A(), w = await (await fetch(g, {
          headers: {
            ...o,
            Accept: "application/vnd.apple.pkpass"
          }
        })).blob(), n = window.URL.createObjectURL(w);
        a.download = `${d}.pkpass`, a.href = n, a.click(), a.removeAttribute("href"), a.removeAttribute("download"), window.URL.revokeObjectURL(n);
      } catch {
        throw new Error("Failed to download loyalty card");
      }
  };
  return /* @__PURE__ */ l("div", { className: "p-0 md:p-4 grid grid-cols-2 gap-4", children: [
    /* @__PURE__ */ l("div", { className: "col-span-2", children: [
      /* @__PURE__ */ e("h3", { className: "mb-6 text-200/6 pr-6", children: t("loyalty-benefits:titleCongratsNewVdp") }),
      s && /* @__PURE__ */ e("div", { className: "mb-6 text-75/6", children: t("loyalty-benefits:welcomeAddWallet") }),
      /* @__PURE__ */ l("div", { className: "flex flex-row", children: [
        /* @__PURE__ */ e("div", { className: "w-6 h-6 mr-1", children: /* @__PURE__ */ e(N, { className: "gamma:text-brand primary karwei:text-ignew-neutral-1000" }) }),
        /* @__PURE__ */ e("p", { children: /* @__PURE__ */ l(L, { t, i18nKey: "loyalty-benefits:welcomeBenefits", children: [
          /* @__PURE__ */ e("strong", { children: "250" }),
          " welkomstpunten t.w.v. €2,50 zijn toegevoegd aan je account"
        ] }) })
      ] })
    ] }),
    /* @__PURE__ */ e("div", { className: "col-span-2", children: /* @__PURE__ */ e("div", { className: "w-full", children: /* @__PURE__ */ e(
      v,
      {
        language: r.language,
        cardNumber: i
      }
    ) }) }),
    s && /* @__PURE__ */ e("div", { className: "col-span-2", children: /* @__PURE__ */ e("a", { onClick: h, className: "cursor-pointer", children: /* @__PURE__ */ e(
      "img",
      {
        src: r.language === "nl" ? "https://mijn.gamma.nl/static/images/apple_wallet_nl_full.svg" : "https://mijn.gamma.nl/static/images/apple_wallet_fr_full.svg",
        className: "w-full",
        alt: "Apple Wallet"
      }
    ) }) }),
    /* @__PURE__ */ e("div", { className: "col-span-2", children: /* @__PURE__ */ e(u, { onClick: f, $wide: !0, children: t("loyalty-benefits:buttonAddPhysicalPass") }) })
  ] });
}
export {
  $ as LoyaltyBenefits
};
