import { createHttpClient as O } from "@intergamma/account";
import { B as ve, a as Ne, b as ye, c as Se, d as we, e as xe, U as _, S as W, t as G, T as q, f as Ce } from "./LoadingHeader-BdPugg2p.js";
import { C as ct, F as ut, H as dt, I as mt, g as ft, L as ht, h as pt, i as gt, j as bt } from "./LoadingHeader-BdPugg2p.js";
import { useTranslation as d } from "react-i18next";
import { useTranslation as Nt } from "react-i18next";
import { jsx as n, jsxs as a } from "react/jsx-runtime";
import { Container as Ae } from "@intergamma/container";
import { useState as p, useRef as D, useEffect as S, useMemo as v, useCallback as z, lazy as M, Suspense as J, Children as Ee, isValidElement as ke, cloneElement as Oe } from "react";
import { useTheme as T } from "styled-components";
import { useQuery as j, useMutation as _e, useQueryClient as V } from "@tanstack/react-query";
import { DialogRoot as De, UnstyledDialogTrigger as Ie, DrawerContent as Be, DialogTrigger as Q, DialogBottomSheet as Me, DialogExtended as Te } from "@intergamma/dialog";
import { DropdownRoot as U, DropdownTrigger as L, DropdownContent as R } from "@intergamma/dropdown";
import { cn as y } from "@intergamma/common/cn";
import { cn as A } from "@intergamma/common";
import { MapPin as Ue, LogOut as Le, ChevronRight as X, ArrowLeft as Re, X as Ye, ChevronDown as Fe, ChevronUp as Pe, Star as ze } from "react-feather";
import { Anchor as N } from "@intergamma/anchor";
import { useConfig as x } from "@intergamma/config";
import { MainSearchBar as Qe, SearchBar as He } from "@intergamma/search-bar";
import { ListItem as w } from "@intergamma/list";
import { dispatchAdobeEvent as I } from "@intergamma/adobe-tracking";
import { signIn as $e } from "aws-amplify/auth";
import { localStorage as H } from "@intergamma/storage";
function Z(e) {
  var s;
  const r = O(e.SHOPFRONT_URL, {
    withCredentials: !0
  }), t = ((s = e.locales) == null ? void 0 : s[0].split(/[_-]/)[0]) ?? "nl";
  return {
    getCategories(i) {
      return r.get(e.country === "be" ? `/${t}/resources/menu/${i}` : `/resources/menu/${i}`).then(
        (o) => o.map(
          (l) => ({
            ...l,
            type: "category",
            dataUrl: Ke(l.dataUrl)
          })
        )
      );
    }
  };
}
function Ke(e) {
  return e.replace("https://www.acceptatie.gamma.nl/resources/menu/", "").replace("https://www.gamma.nl/resources/menu/", "").replace("https://www.acceptatie.gamma.be/nl/resources/menu/", "").replace("https://www.acceptatie.gamma.be/fr/resources/menu/", "").replace("https://www.gamma.be/nl/resources/menu/", "").replace("https://www.gamma.be/fr/resources/menu/", "").replace("https://www.acceptatie.karwei.nl/resources/menu/", "").replace("https://www.karwei.nl/resources/menu/", "");
}
function We({ category: e, onSelect: r }) {
  return /* @__PURE__ */ n(
    "li",
    {
      className: y(
        "m-0 block h-[inherit] break-inside-avoid border-b gamma:border-[#a1b5c5] karwei:border-[#dce1e8] md:flex md:h-10 md:items-center md:border-none",
        e.divider && "mb-10 md:mb-0"
      ),
      children: /* @__PURE__ */ a(
        "a",
        {
          className: y(
            "group m-0 flex h-auto w-full grow cursor-pointer items-center rounded-lg border-none bg-transparent py-4 text-left text-[1em] leading-[1em] text-brand-primary no-underline",
            "hover:underline",
            "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400",
            "md:w-auto md:grow-0 md:p-0"
          ),
          href: e.url,
          onClick: (t) => {
            e.hasSubCategories && (r(e), t.preventDefault());
          },
          children: [
            e.icon === "store" && /* @__PURE__ */ n(Ue, { className: "mr-2 mt-[inherit] !size-4 align-middle" }),
            e.icon === "logout" && /* @__PURE__ */ n(Le, { className: "mr-2 mt-[inherit] !size-4 align-middle" }),
            e.name,
            e.hasSubCategories && /* @__PURE__ */ n(X, { className: "ml-1 size-4 text-brand-primary transition motion-safe:group-hover:translate-x-[5px] md:right-[inherit] md:size-4" })
          ]
        }
      )
    }
  );
}
function Ge({ categories: e, onSelect: r }) {
  return /* @__PURE__ */ n("div", { className: "relative", children: /* @__PURE__ */ n("div", { className: "overflow-hidden md:min-h-[205px]", children: /* @__PURE__ */ n("ul", { className: "mx-[10px] list-none columns-1 gap-x-5 p-0 pb-5 text-[1em] md:mx-0 md:columns-3 md:px-5 md:text-[0.9em] lg:columns-4", children: e.map((t) => /* @__PURE__ */ n(
    We,
    {
      category: t,
      onSelect: r
    },
    t.uid
  )) }) }) });
}
function qe({ lastBreadcrumb: e }) {
  const { t: r } = d("ig-header");
  return /* @__PURE__ */ n("div", { className: "rounded-b-lg px-[10px] pb-[15px] md:px-5 md:py-[15px] md:text-75/normal gamma:md:bg-[#f0f2f6] karwei:md:bg-[#f1f4f8]", children: /* @__PURE__ */ n(
    N,
    {
      className: "no-underline hover:underline",
      href: e.url,
      children: e.uid === "root" || e.uid === "root-mobile" ? r("ShowEntireCatalog") : r("ShowAllIn", { name: e.name })
    }
  ) });
}
function Je({
  breadcrumbs: e,
  onBack: r,
  onClose: t
}) {
  const { t: s } = d("ig-header"), i = e.length;
  return /* @__PURE__ */ a("div", { className: "mt-[10px] flex items-center gap-2 p-[10px] md:px-[20px]", children: [
    i >= 2 && /* @__PURE__ */ n(
      "button",
      {
        className: A(
          "grid size-8 cursor-pointer place-items-center rounded-full text-brand-primary md:hidden",
          "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
        ),
        onClick: () => r(e[i - 2]),
        type: "button",
        children: /* @__PURE__ */ n(Re, { className: "size-6 stroke-1" })
      }
    ),
    /* @__PURE__ */ n("ul", { className: "m-0 flex flex-1 list-none items-center gap-2 text-ellipsis whitespace-nowrap", children: e.map((o, l) => /* @__PURE__ */ a(
      "li",
      {
        className: "group m-0 hidden items-center gap-2 p-0 last:flex md:flex",
        children: [
          l === i - 1 ? /* @__PURE__ */ n("span", { className: "font-bold", title: o.name, children: o.name }) : /* @__PURE__ */ n(
            "a",
            {
              className: A(
                "rounded-lg text-inherit underline hover:text-brand-primary",
                "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
              ),
              href: o.url,
              onClick: (m) => {
                m.preventDefault(), r(o);
              },
              children: o.name
            }
          ),
          /* @__PURE__ */ n(X, { className: "size-4 group-last:hidden" })
        ]
      },
      o.uid
    )) }),
    t && /* @__PURE__ */ n(
      "button",
      {
        className: A(
          "grid size-8 cursor-pointer place-items-center rounded-full text-brand-primary",
          "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
        ),
        type: "button",
        onClick: t,
        "aria-label": s("close"),
        children: /* @__PURE__ */ n(Ye, { className: "size-6 stroke-1" })
      }
    )
  ] });
}
function $({
  onClose: e,
  onSelect: r,
  breadcrumbs: t,
  categories: s,
  onBack: i,
  lastBreadcrumb: o
}) {
  var h;
  const [l, m] = p(!1), c = D(!0), f = D(
    s ? (h = s[0]) == null ? void 0 : h.name : void 0
  );
  return S(() => {
    var g;
    const b = s ? (g = s[0]) == null ? void 0 : g.name : void 0;
    f.current !== b && b && (f.current = b, m(!1), setTimeout(() => {
      c.current && m(!0);
    }, 300));
  }, [s, o.name]), S(
    () => () => {
      c.current = !1;
    },
    []
  ), /* @__PURE__ */ a(
    "nav",
    {
      className: A(
        "overflow-hidden",
        !l && "motion-safe:animate-menu-slider"
      ),
      children: [
        /* @__PURE__ */ n(
          Je,
          {
            onBack: i,
            breadcrumbs: t,
            onClose: e
          }
        ),
        /* @__PURE__ */ n(Ge, { categories: s ?? [], onSelect: r }),
        o.type === "category" && /* @__PURE__ */ n(qe, { lastBreadcrumb: o })
      ]
    }
  );
}
function je({
  menuItem: e,
  store: r
}) {
  const t = T(), { t: s } = d("ig-header");
  if (e.type === "category")
    return [];
  if (e.uid === "root-mobile") {
    const i = [
      {
        dataUrl: "categories",
        uid: "catalog",
        type: "category",
        name: s("Categories"),
        url: s("/catalog"),
        hasSubCategories: !0
      },
      {
        uid: "advice",
        type: "menu-item",
        name: s("Advice"),
        url: s("/advice"),
        hasSubCategories: !1
      },
      {
        uid: "services",
        type: "menu-item",
        name: s("ServicesMobile"),
        url: s("/services"),
        hasSubCategories: !1
      },
      {
        uid: "promotions",
        type: "menu-item",
        name: s("Promotions"),
        url: s("/promotions"),
        hasSubCategories: !1
      }
    ];
    return t.name === "karwei_nl" && i.push({
      uid: "inspiration",
      type: "menu-item",
      name: s("Inspiration"),
      url: s("/inspiration"),
      hasSubCategories: !1
    }), i.push({
      uid: "store",
      name: r != null && r.name ? r.name : s("Stores"),
      url: s("/stores"),
      hasSubCategories: !1,
      type: "menu-item",
      icon: "store",
      divider: !0
    }), i;
  }
  return [];
}
function K({
  store: e,
  rootCategory: r
}) {
  const [t, s] = p([r]);
  S(() => {
    s([r]);
  }, [r]);
  const i = t[t.length - 1], o = je({
    menuItem: i,
    store: e
  });
  function l(c) {
    const f = t.findIndex(
      (h) => h.uid === c.uid
    );
    f > -1 && s(t.slice(0, f + 1));
  }
  function m(c) {
    c.hasSubCategories && s([...t, c]);
  }
  return {
    simpleMenuItems: o,
    onBack: l,
    onSelect: m,
    breadcrumbs: t,
    setBreadcrumbs: s,
    lastBreadcrumb: i
  };
}
function Ve() {
  var e;
  (e = document.querySelector("html")) == null || e.classList.add("panel-active");
}
function Xe() {
  var e;
  (e = document.querySelector("html")) == null || e.classList.remove("panel-active");
}
function Ze({
  categories: e,
  store: r,
  onSelect: t
}) {
  const { t: s } = d("ig-header"), [i, o] = p(!1), [l, m] = p(!1), c = D(null), f = v(
    () => new URLSearchParams(
      typeof window < "u" ? window.location.search : void 0
    ),
    []
  ), h = v(
    () => ({
      dataUrl: "categories",
      uid: "root",
      name: s("Catalog"),
      url: s("/catalog"),
      hasSubCategories: !0,
      type: "category"
    }),
    [s]
  ), b = v(
    () => ({
      uid: "root-mobile",
      name: s("Menu"),
      url: "#",
      hasSubCategories: !0,
      type: "menu-item"
    }),
    [s]
  ), {
    breadcrumbs: g,
    lastBreadcrumb: k,
    onBack: ue,
    onSelect: de
  } = K({ store: r, rootCategory: h }), {
    breadcrumbs: me,
    lastBreadcrumb: C,
    onBack: fe,
    onSelect: he,
    simpleMenuItems: pe
  } = K({ store: r, rootCategory: b });
  function ge(u) {
    u.type === "category" && t(u), he(u);
  }
  function be(u) {
    u.type === "category" && t(u), de(u);
  }
  S(() => {
    k.type === "category" && t(k);
  }, [k, t]), S(() => {
    C.type === "category" && t(C);
  }, [C, t]);
  function P(u) {
    u ? Ve() : Xe(), m(u);
  }
  return /* @__PURE__ */ n(ve, { "aria-label": s("MainNavigation"), ref: c, children: /* @__PURE__ */ a(Ne, { children: [
    /* @__PURE__ */ a(ye, { children: [
      /* @__PURE__ */ n("div", { className: "contents md:hidden", children: /* @__PURE__ */ a(
        De,
        {
          open: l,
          onOpenChange: (u) => P(u),
          modal: !1,
          children: [
            /* @__PURE__ */ n(Ie, { asChild: !0, children: /* @__PURE__ */ n(Se, {}) }),
            /* @__PURE__ */ n(Be, { container: c.current, children: /* @__PURE__ */ n(
              $,
              {
                onClose: () => P(!1),
                breadcrumbs: me,
                categories: C.type === "category" && e ? e : pe,
                lastBreadcrumb: C,
                onBack: fe,
                onSelect: (u) => ge(u)
              }
            ) }),
            l && /* @__PURE__ */ n("div", { className: "fixed inset-0 z-[9999] bg-[hsl(200deg_60%_12%/75%)] motion-safe:animate-fade-in" })
          ]
        }
      ) }),
      /* @__PURE__ */ n("div", { className: "hidden md:contents", children: /* @__PURE__ */ a(U, { open: i, onOpenChange: o, children: [
        /* @__PURE__ */ n(
          L,
          {
            className: y(
              "h-[46px] rounded-lg",
              "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
            ),
            children: /* @__PURE__ */ n(we, { isOpen: i })
          }
        ),
        /* @__PURE__ */ a(
          R,
          {
            className: "!z-20 !shadow-[0_0_0_999em_rgba(0,0,0,0.3),0_1px_4px_rgba(0,0,0,0.3)]",
            container: c.current,
            align: "start",
            alignOffset: -121,
            sideOffset: -10,
            children: [
              f.has("shadow") && // eslint-disable-next-line tailwindcss/no-custom-classname
              /* @__PURE__ */ n("div", { className: "flex flex-col items-center bg-brand-primary p-4", children: "😎 😎 😎" }),
              /* @__PURE__ */ n(
                $,
                {
                  onClose: () => o(!1),
                  breadcrumbs: g,
                  categories: e,
                  lastBreadcrumb: k,
                  onBack: ue,
                  onSelect: (u) => be(u)
                }
              )
            ]
          }
        )
      ] }) })
    ] }),
    /* @__PURE__ */ n("div", { className: "hidden md:contents", children: /* @__PURE__ */ n(xe, {}) })
  ] }) });
}
const E = (...e) => j(...e), ee = (...e) => _e(...e), Y = "base-navigation", Hn = Y;
function en() {
  const [e, r] = p(
    void 0
  ), t = x(), s = v(
    () => Z(t),
    [t]
  ), i = z(
    (c) => s.getCategories(c),
    [s]
  ), o = z(async () => e ? i(e.dataUrl) : [], [e, i]), { data: l } = E(
    [Y, e == null ? void 0 : e.uid],
    o,
    {
      suspense: !1,
      useErrorBoundary: !1,
      keepPreviousData: !0
    }
  );
  function m(c) {
    r(c);
  }
  return { categories: l, onSelect: m };
}
function $n({ store: e }) {
  const { categories: r, onSelect: t } = en();
  return /* @__PURE__ */ n(Ze, { store: e, categories: r, onSelect: t });
}
function Kn({
  className: e,
  children: r,
  ...t
}) {
  return /* @__PURE__ */ n(
    "div",
    {
      className: y(
        "absolute right-0 top-0 flex h-[50px] items-center gap-[10px] pl-[10px] md:relative md:h-9 lg:h-[46px]",
        e
      ),
      ...t,
      children: r
    }
  );
}
const nn = M(
  () => import("@intergamma/account/account").then((e) => ({
    default: e.Account
  }))
), tn = M(
  () => import("@intergamma/account/loyalty-benefits").then((e) => ({
    default: e.LoyaltyBenefits
  }))
);
function Wn({
  user: e,
  container: r,
  onLogin: t,
  ...s
}) {
  const [i, o] = p(!1), [l, m] = p(!1);
  document.addEventListener("openAccountDialog", () => {
    o(!0);
  });
  function c() {
    o(!1);
  }
  S(() => {
    const g = document.getElementById("onetrust-consent-sdk");
    g && (g.style.pointerEvents = "auto");
  }, []);
  const { isMobile: f } = x(), { t: h } = d("ig-header"), b = f ? Me : Te;
  return e != null && e.isLoggedIn ? l ? /* @__PURE__ */ n(
    b,
    {
      trigger: /* @__PURE__ */ n(Q, { children: /* @__PURE__ */ n(_, { user: e, href: h("/myaccount") }) }),
      open: i,
      onOpenChange: o,
      container: r,
      dialogTitle: h("LoyaltyCard"),
      children: /* @__PURE__ */ n(
        tn,
        {
          close: () => c(),
          loyaltyCardNumber: e.defaultLoyaltyCardNumber
        }
      )
    }
  ) : /* @__PURE__ */ n(_, { user: e, href: h("/myaccount") }) : /* @__PURE__ */ n(
    b,
    {
      trigger: /* @__PURE__ */ n(Q, { asChild: !0, children: /* @__PURE__ */ n(_, { user: e }) }),
      open: i,
      onOpenChange: o,
      container: r,
      dialogTitle: h("Login"),
      children: /* @__PURE__ */ n(J, { children: /* @__PURE__ */ n(
        nn,
        {
          ...s,
          setShowLoyaltyBenefits: m,
          onClose: () => c(),
          onLogin: (g) => t(g).then(() => {
            l || c();
          }),
          initLocation: "global_header"
        }
      ) })
    }
  );
}
function Gn() {
  return /* @__PURE__ */ n(W, { children: /* @__PURE__ */ n(Qe, {}) });
}
function qn(e) {
  return /* @__PURE__ */ n(W, { children: /* @__PURE__ */ n(He, { ...e }) });
}
function ne() {
  return /* @__PURE__ */ n(Fe, { className: "pointer-events-none", size: 16 });
}
function te() {
  return /* @__PURE__ */ n(Pe, { className: "pointer-events-none", size: 16 });
}
function Jn({
  alternateURL: e,
  container: r
}) {
  const { t } = d("ig-header"), [s, i] = p(!1);
  return T().name !== "gamma_be" ? null : /* @__PURE__ */ a(U, { open: s, onOpenChange: i, children: [
    /* @__PURE__ */ n(L, { asChild: !0, children: /* @__PURE__ */ a(
      "button",
      {
        className: A(
          G,
          "h-auto cursor-pointer border-none bg-none"
        ),
        children: [
          /* @__PURE__ */ n("strong", { children: t("Language") }),
          s ? /* @__PURE__ */ n(te, {}) : /* @__PURE__ */ n(ne, {})
        ]
      }
    ) }),
    /* @__PURE__ */ n(
      R,
      {
        className: "!z-[99999] !w-max",
        align: "end",
        onOpenAutoFocus: rn,
        container: r,
        children: /* @__PURE__ */ n("div", { className: "p-5 text-75/normal", children: /* @__PURE__ */ a("ul", { className: "m-0 list-none p-0", children: [
          /* @__PURE__ */ n(w, { children: /* @__PURE__ */ n(
            N,
            {
              className: "whitespace-nowrap no-underline hover:underline",
              lang: "nl",
              href: (e == null ? void 0 : e.nl) ?? "/nl",
              children: "Nederlands"
            }
          ) }),
          /* @__PURE__ */ n(w, { children: /* @__PURE__ */ n(
            N,
            {
              className: "whitespace-nowrap no-underline hover:underline",
              lang: "fr",
              href: (e == null ? void 0 : e.fr) ?? "/fr",
              children: "Français"
            }
          ) })
        ] }) })
      }
    )
  ] });
}
function rn(e) {
  const r = e.target;
  if (!r)
    return;
  const t = r.querySelector("a");
  t && (e.preventDefault(), t.focus());
}
function F(e) {
  const r = O(e, {
    withCredentials: !0
  });
  return {
    getUser() {
      return r.get("/api/session-data");
    },
    getNearbyStores(t) {
      return r.get(
        `/api/session-data/nearby-stores/${t.uid}`
      );
    },
    async changeStore(t) {
      return r.put(
        `/api/session-data/preferred-store/${t.uid}`
      );
    },
    async logout() {
      const t = await this.getGatewayCSRF();
      return r.post("/gateway/logout", null, {
        headers: {
          "X-IG-CSRF-TOKEN": t
        }
      });
    },
    async login(t) {
      const s = await this.getGatewayCSRF();
      return r.post(
        "/gateway/addcard/login",
        t,
        {
          headers: {
            "X-IG-CSRF-TOKEN": s
          }
        }
      );
    },
    async getGatewayCSRF() {
      return (await r.get("/gateway/session")).csrfToken;
    }
  };
}
function jn() {
  const { MYACCOUNT_URL: e } = x(), r = V(), t = v(
    () => F(e),
    [e]
  );
  function s(i) {
    return t.changeStore(i).then(() => {
      document == null || document.body.dispatchEvent(
        new window.CustomEvent("refreshStore", { detail: i })
      ), I({
        type: "change_store",
        data: {
          user_selected_value: i
        }
      });
    });
  }
  return ee(s, {
    useErrorBoundary: !1,
    onSuccess: () => {
      r.invalidateQueries();
    }
  });
}
const re = "nearby-store";
function Vn(e) {
  const { MYACCOUNT_URL: r } = x(), t = v(
    () => F(r),
    [r]
  );
  function s() {
    return e ? t.getNearbyStores(e) : [];
  }
  return E([re, e], s, {
    suspense: !1,
    useErrorBoundary: !1
  });
}
function se({
  className: e,
  children: r,
  ...t
}) {
  return /* @__PURE__ */ n(
    "div",
    {
      className: y(
        "rounded-b-lg p-5 gamma:bg-[#f0f2f6] karwei:bg-[#f1f4f8]",
        e
      ),
      ...t,
      children: r
    }
  );
}
function ie({
  children: e,
  className: r,
  ...t
}) {
  return /* @__PURE__ */ n(
    "h3",
    {
      className: y(
        "mb-5 text-[1.2em] font-bold text-brand-primary",
        r
      ),
      ...t,
      children: e
    }
  );
}
function sn() {
  const { t: e } = d("ig-header");
  return /* @__PURE__ */ a(se, { children: [
    /* @__PURE__ */ n(ie, { children: e("OtherStoresNearby") }),
    /* @__PURE__ */ n("div", { className: "mb-5 flex flex-col last:mb-0", children: /* @__PURE__ */ n("div", { className: "block w-full", children: /* @__PURE__ */ n("div", { className: "h-5 w-full animate-pulse rounded-sm bg-[#ddd]" }) }) }),
    /* @__PURE__ */ n("div", { className: "mb-5 flex flex-col last:mb-0", children: /* @__PURE__ */ n("div", { className: "block w-full", children: /* @__PURE__ */ n("div", { className: "h-5 w-full animate-pulse rounded-sm bg-[#ddd]" }) }) }),
    /* @__PURE__ */ n("div", { className: "mb-5 flex flex-col last:mb-0", children: /* @__PURE__ */ n("div", { className: "block w-full", children: /* @__PURE__ */ n("div", { className: "h-5 w-full animate-pulse rounded-sm bg-[#ddd]" }) }) }),
    /* @__PURE__ */ a("ul", { className: "m-0 mt-5 list-none p-0", children: [
      /* @__PURE__ */ n(w, { children: /* @__PURE__ */ n(N, { href: e("/stores/search"), children: e("AllStores") }) }),
      /* @__PURE__ */ n(w, { children: /* @__PURE__ */ n(N, { href: e("/stores/holidays"), children: e("SpecialOpeningHours") }) })
    ] })
  ] });
}
function on({ nearbyStore: e, onSaveStore: r }) {
  const { t } = d("ig-header");
  return /* @__PURE__ */ a("div", { className: "mb-5 grid grid-cols-2 last:mb-0", children: [
    /* @__PURE__ */ n("div", { className: "flex items-center", children: /* @__PURE__ */ n(
      "a",
      {
        className: "font-bold text-black no-underline hover:underline",
        href: t("/stores/details", e),
        children: e.name
      }
    ) }),
    /* @__PURE__ */ n("div", { className: "flex items-center", children: /* @__PURE__ */ a(
      "button",
      {
        className: "group flex h-auto cursor-pointer items-center border-none bg-transparent p-0 text-[1em] hover:underline",
        onClick: () => r(e),
        children: [
          /* @__PURE__ */ n(ze, { className: "mr-[5px] rounded-[20px] bg-white !p-[5px] group-hover:fill-current gamma:text-brand-primary karwei:text-[#111316]" }),
          t("SaveAsMyStore")
        ]
      }
    ) })
  ] });
}
function an({ nearbyStores: e, onSaveStore: r }) {
  const { t } = d("ig-header");
  return /* @__PURE__ */ a(se, { children: [
    /* @__PURE__ */ n(ie, { children: t("OtherStoresNearby") }),
    e.map((s) => /* @__PURE__ */ n(
      on,
      {
        nearbyStore: s,
        onSaveStore: r
      },
      s.uid
    )),
    /* @__PURE__ */ a("ul", { className: "m-0 mt-5 list-none p-0", children: [
      /* @__PURE__ */ n(w, { children: /* @__PURE__ */ n(N, { href: t("/stores/search"), children: t("AllStores") }) }),
      /* @__PURE__ */ n(w, { children: /* @__PURE__ */ n(N, { href: t("/stores/holidays"), children: t("SpecialOpeningHours") }) })
    ] })
  ] });
}
const ln = [
  "SUNDAY",
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY"
];
function cn({ store: e }) {
  const { t: r } = d("ig-header");
  return /* @__PURE__ */ a("div", { className: "p-5", children: [
    /* @__PURE__ */ n("h2", { className: "mb-5 text-[1.3em] font-bold text-brand-primary", children: e.name }),
    /* @__PURE__ */ a("div", { className: "mb-5 flex last:mb-0", children: [
      /* @__PURE__ */ n("div", { className: "flex-1 basis-1/2", children: /* @__PURE__ */ a("div", { className: "leading-normal", children: [
        e.address.street,
        " ",
        e.address.streetNumber,
        e.address.apartment ? `/${e.address.apartment}` : null,
        /* @__PURE__ */ n("br", {}),
        e.address.zipCode,
        " ",
        e.address.city,
        /* @__PURE__ */ n("br", {}),
        e.address.phone,
        /* @__PURE__ */ n("br", {}),
        /* @__PURE__ */ n("br", {}),
        /* @__PURE__ */ n(N, { href: r("/stores/details", { slug: e.slug }), children: r("ShowOnMap") })
      ] }) }),
      /* @__PURE__ */ n("div", { className: "flex-1 basis-1/2", children: e.openingHours.map((t) => /* @__PURE__ */ a("div", { className: "mb-5 flex last:mb-0", children: [
        /* @__PURE__ */ n("div", { className: "flex-1 basis-1/2", children: r(ln[t.dayOfWeek]) }),
        /* @__PURE__ */ n("div", { className: "flex-1 basis-1/2", children: t.opens && t.closes ? r("Timespan", { opens: t.opens, closes: t.closes }) : r("Closed") })
      ] }, t.dayOfWeek)) })
    ] })
  ] });
}
function un({
  store: e,
  isLoadingNearbyStores: r,
  ...t
}) {
  return /* @__PURE__ */ a("div", { className: "w-[500px] font-primary text-75/normal tabular-nums", children: [
    /* @__PURE__ */ n(cn, { store: e }),
    r ? /* @__PURE__ */ n(sn, {}) : /* @__PURE__ */ n(an, { ...t })
  ] });
}
function dn() {
  const { t: e } = d("ig-header");
  return /* @__PURE__ */ n(q, { href: e("/stores/search"), children: /* @__PURE__ */ n("strong", { children: e("Stores") }) });
}
function Xn({
  store: e,
  isLoadingNearbyStores: r,
  container: t,
  ...s
}) {
  const [i, o] = p(!1);
  return e ? /* @__PURE__ */ a(U, { open: i, onOpenChange: o, children: [
    /* @__PURE__ */ a(L, { className: G, children: [
      /* @__PURE__ */ n("strong", { children: e.name }),
      i ? /* @__PURE__ */ n(te, {}) : /* @__PURE__ */ n(ne, {})
    ] }),
    /* @__PURE__ */ n(
      R,
      {
        className: "z-[999999] !max-w-[500px]",
        align: "end",
        container: t,
        children: /* @__PURE__ */ n(
          un,
          {
            ...s,
            store: e,
            isLoadingNearbyStores: r
          }
        )
      }
    )
  ] }) : /* @__PURE__ */ n(dn, {});
}
function Zn() {
  const { t: e } = d("ig-header");
  return /* @__PURE__ */ n(q, { href: e("/stores/search"), children: /* @__PURE__ */ n(Ce, { className: "w-[120px]" }) });
}
function et({ children: e }) {
  const { t: r } = d("ig-header");
  return /* @__PURE__ */ n(
    "nav",
    {
      className: y(
        "absolute right-[220px] top-0 hidden h-[46px] items-center",
        "md:relative md:right-4 md:top-0 md:flex md:h-9",
        "lg:absolute lg:right-0 lg:h-[46px]"
      ),
      "aria-label": r("UserNavigation"),
      children: /* @__PURE__ */ n("ul", { className: "m-0 flex list-none items-center gap-[25px] p-0", children: e && Ee.map(e, (t) => ke(t) ? /* @__PURE__ */ n("li", { children: Oe(t) }) : null) })
    }
  );
}
const mn = M(
  () => import("./Alert-BuGmuX9N.js").then((e) => ({
    default: e.Alert
  }))
);
function fn({ alerts: e, onDismissAlert: r }) {
  return /* @__PURE__ */ n(J, { fallback: null, children: e.map(({ sys: t, fields: s }) => /* @__PURE__ */ n(
    mn,
    {
      label: s.description,
      id: t.id,
      onDismissAlert: r
    },
    t.id
  )) });
}
function hn({ alerts: e, onDismissAlert: r }) {
  return /* @__PURE__ */ n(Ae, { children: /* @__PURE__ */ n(fn, { alerts: e, onDismissAlert: r }) });
}
const B = "disabledAlerts";
function pn() {
  if (typeof sessionStorage > "u")
    return [];
  const e = sessionStorage.getItem(B);
  if (!e)
    return [];
  try {
    return JSON.parse(e);
  } catch {
    return [];
  }
}
function gn(e) {
  if (typeof sessionStorage > "u")
    return;
  const r = sessionStorage.getItem(B) || "[]";
  try {
    const t = JSON.parse(r);
    t.includes(e) || (t.push(e), sessionStorage.setItem(B, JSON.stringify(t)));
  } catch {
  }
}
const bn = O("https://cdn.contentful.com/spaces/");
function vn({
  contentfulSpaceId: e,
  contentfulToken: r,
  contentfulLocale: t
}) {
  const s = "alert", i = r;
  return bn.get(
    `${e}/environments/master/entries?content_type=${s}&access_token=${i}&locale=${t}`
  ).then((o) => {
    var l;
    return ((l = o == null ? void 0 : o.items) == null ? void 0 : l.length) > 0 ? o.items : [];
  });
}
const oe = "alerts";
function Nn() {
  var c;
  const [e, r] = p(pn), { contentfulSpaceId: t, contentfulToken: s } = T(), { i18n: i } = d(), o = i.language.substring(0, 2) === "fr" ? "fr" : "nl", l = E(
    [oe],
    () => vn({
      contentfulLocale: o,
      contentfulSpaceId: t,
      contentfulToken: s
    }),
    {
      useErrorBoundary: !1,
      suspense: !1
    }
  );
  function m(f) {
    gn(f), r([...e, f]);
  }
  return {
    alerts: ((c = l.data) == null ? void 0 : c.filter(
      (f) => !e.includes(f.sys.id)
    )) ?? [],
    onDismissAlert: m
  };
}
function nt() {
  const { alerts: e, onDismissAlert: r } = Nn();
  return /* @__PURE__ */ n(hn, { alerts: e, onDismissAlert: r });
}
function yn(e) {
  const r = O(e, {
    withCredentials: !0
  });
  return {
    async getNumberOfProducts() {
      const t = await r.get(
        "/resources/cart/numberOfProducts"
      );
      return Number.isNaN(t) ? 0 : t;
    }
  };
}
const ae = "number-of-products";
function tt() {
  const { CHECKOUT_URL: e } = x(), r = v(
    () => yn(e),
    [e]
  );
  function t() {
    return r.getNumberOfProducts();
  }
  return E([ae], t, {
    suspense: !1,
    useErrorBoundary: !1
  });
}
const Sn = "wishlist";
function wn() {
  var r;
  if (typeof H > "u")
    return 0;
  const e = H.getItem(Sn);
  return e ? (r = JSON.parse(e)) == null ? void 0 : r.length : 0;
}
const le = "number-of-favorites";
function rt() {
  return j([le], wn, {
    suspense: !1,
    useErrorBoundary: !1,
    initialData: 0
  });
}
const ce = "user";
function st() {
  const { MYACCOUNT_URL: e } = x(), r = v(
    () => F(e),
    [e]
  );
  return E([ce], r.getUser, {
    useErrorBoundary: !1,
    suspense: !1
  });
}
function it() {
  const { t: e } = d("ig-header"), r = V();
  function t({ body: s }) {
    return $e({
      username: s.customerId,
      password: s.password,
      options: {
        authFlowType: "USER_PASSWORD_AUTH"
      }
    }).catch((i) => {
      const { name: o, message: l } = i;
      throw o === "NotAuthorizedException" ? l === "Password attempts exceeded" ? new Error(e("loginTooManyAttempts")) : new Error(e("loginInvalidPassword")) : o === "UserNotFoundException" ? new Error(e("loginUserNotFound")) : new Error(e("loginSomethingWentWrong"));
    });
  }
  return ee(t, {
    useErrorBoundary: !1,
    onSuccess: (s, { disableAdobeEvent: i = !1, body: o }) => {
      i || I({
        type: "login_header",
        data: {
          kdbid: o.customerId
        }
      }), r.resetQueries({
        queryKey: [
          oe,
          Y,
          re,
          le,
          ae,
          ce
        ]
      });
    },
    onError: (s) => {
      I({
        type: "login_error_header",
        data: {
          user_message_type: (s == null ? void 0 : s.message) ?? ""
        }
      });
    }
  });
}
function ot(e) {
  return Z(e).getCategories;
}
export {
  oe as ALERTS_QUERY_KEY,
  Y as BASE_NAVIGATION_QUERY_KEY,
  Ze as BaseNavigation,
  ct as CartIconNavigation,
  ut as FavoritesIconNavigation,
  hn as HeaderAlerts,
  dt as HeaderContainer,
  mt as IconsNavigation,
  Kn as IconsNavigationContainer,
  Jn as LanguageSelector,
  ft as LoadingBaseNavigation,
  ht as LoadingHeader,
  pt as LoadingIconsNavigation,
  gt as LoadingSearch,
  Zn as LoadingStores,
  bt as LoadingTopNavigation,
  $n as MainBaseNavigation,
  nt as MainHeaderAlerts,
  Gn as MainSearch,
  re as NEARBY_STORES_QUERY_KEY,
  le as NUMBER_OF_FAVORITES_QUERY_KEY,
  Hn as QUERY_KEY,
  qn as Search,
  W as SearchContainer,
  Xn as StoreLocatorDropdown,
  et as TopNavigation,
  ce as USER_QUERY_KEY,
  Wn as UserIconNavigation,
  _ as UserIconNavigationButton,
  ot as createGetAssortimentCategories,
  Ve as disablePageScroll,
  Xe as enablePageScroll,
  vn as getAlerts,
  pn as getSessionDisabledAlert,
  gn as setSessionDisabledAlert,
  Nn as useAlertsQuery,
  en as useCategoriesQuery,
  jn as useChangeStoreMutation,
  it as useLoginMutation,
  Vn as useNearbyStoresQuery,
  rt as useNumberOfFavorites,
  tt as useNumberOfProductsQuery,
  Nt as useTranslation,
  st as useUserQuery
};
