import { jsxs as l, jsx as e } from "react/jsx-runtime";
import { forwardRef as $, createRef as x } from "react";
import * as t from "@radix-ui/react-dialog";
import { DialogTitle as g } from "@radix-ui/react-dialog";
import r, { keyframes as s, css as D } from "styled-components";
import { DialogRoot as m } from "./DialogRoot.js";
import { overlayShow as b, duration as u } from "./Dialog.js";
import { Dialog as oo, DialogContent as eo, StyledDialogOverlay as to } from "./Dialog.js";
import { DialogCloseIcon as no } from "./DialogCloseIcon.js";
import { DialogClose as ao } from "./DialogClose.js";
import { DialogDescription as co } from "./DialogDescription.js";
import { DialogTitle as po } from "./DialogTitle.js";
import { DialogTrigger as mo, UnstyledDialogTrigger as uo } from "./DialogTrigger.js";
import { X as C } from "react-feather";
function Q({
  trigger: o,
  animationDirection: i = "ltr",
  children: a,
  ...n
}) {
  return /* @__PURE__ */ l(m, { ...n, children: [
    o,
    /* @__PURE__ */ e(P, { $animationDirection: i, children: a })
  ] });
}
function k({
  children: o,
  $animationDirection: i,
  container: a,
  ...n
}, c) {
  return /* @__PURE__ */ l(t.Portal, { container: a, children: [
    /* @__PURE__ */ e(
      X,
      {
        ...n,
        $animationDirection: i,
        ref: c,
        children: o
      }
    ),
    /* @__PURE__ */ e(R, {})
  ] });
}
const S = $(k), y = 200, z = s`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`, O = s`
  from {
    opacity: 0;
    transform: translateX(-100%);
    backdrop-filter: blur(0);
  }

  to {
    opacity: 1;
    transform: translateX(0);
    backdrop-filter: blur(2px);
  }
`, I = s`
  from {
    opacity: 0;
    transform: translateX(100%);
    backdrop-filter: blur(0);
  }

  to {
    opacity: 1;
    transform: translateX(0);
    backdrop-filter: blur(2px);
  }
`, R = r(t.Overlay)`
  // Based on theme.neutral700
  background-color: hsl(200deg 60% 12% / 75%);
  z-index: 9999;

  position: fixed;
  inset: 0;

  @media (prefers-reduced-motion: no-preference) {
    animation: ${z} ${y}ms cubic-bezier(0, 0, 0.2, 1);
  }
`, X = r(t.Content)`
  background-color: ${({ theme: o }) => o.colors.white};
  border-radius: 0;
  z-index: 99999;
  overflow: auto;

  box-shadow:
    hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;

  position: fixed;

  left: ${({ $animationDirection: o }) => o === "rtl" ? "auto" : 0};
  right: ${({ $animationDirection: o }) => o === "rtl" ? 0 : "auto"};

  top: 0;
  bottom: 0;
  width: 90vw;
  max-width: 500px;

  @media (prefers-reduced-motion: no-preference) {
    animation: ${({ $animationDirection: o }) => o === "rtl" ? I : O}
      ${y}ms cubic-bezier(0, 0, 0.2, 1);
  }

  &:focus {
    outline: none;
  }
`, P = S;
function w() {
  return /* @__PURE__ */ e(j, { as: t.Close, "data-testid": "close-button", children: /* @__PURE__ */ e(B, {}) });
}
const j = r(t.Close)`
  line-height: 1;
  height: auto;
  cursor: pointer;
  border: none;
  padding: 0;
  background: transparent;
  position: absolute;
  top: 1rem;
  right: 1rem;
  box-shadow: none;

  &:focus {
    box-shadow: none;
  }
`, B = r(C)`
  color: #003878;
`;
function V({
  trigger: o,
  wide: i = !1,
  children: a,
  container: n,
  dialogTitle: c,
  ...d
}) {
  const p = x(), f = (h) => {
    h.target !== p.current && h.preventDefault();
  };
  return /* @__PURE__ */ l(m, { ...d, children: [
    o,
    /* @__PURE__ */ e(t.Portal, { container: n, children: /* @__PURE__ */ e(T, { ref: p, children: /* @__PURE__ */ l(
      E,
      {
        onInteractOutside: f,
        "data-testid": "dialog-content",
        $wide: i,
        children: [
          c && /* @__PURE__ */ e(g, { className: "sr-only", children: c }),
          /* @__PURE__ */ e(w, {}),
          a
        ]
      }
    ) }) })
  ] });
}
const N = s`
  from {
    opacity: 0;
    transform: scale(.96);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
`, T = r(t.Overlay)`
  background-color: hsl(200deg 60% 12% / 75%);
  z-index: 9999;

  position: fixed;
  display: flex;
  align-items: center;
  flex-direction: column;
  inset: 0;
  padding: 2rem 0;
  overflow-y: auto;
  backdrop-filter: blur(2px);

  @media (prefers-reduced-motion: no-preference) {
    animation: ${b} ${u}ms cubic-bezier(0, 0, 0.2, 1);
  }
`, E = r(t.Content)`
  background-color: ${({ theme: o }) => o.colors.white};
  border-radius: 0.8rem;
  z-index: 99999;
  box-shadow:
    hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;

  width: 90vw;
  max-width: ${(o) => o.$wide ? "690px" : "348px"};
  margin: auto 0;

  position: relative;

  @media (prefers-reduced-motion: no-preference) {
    animation: ${N} 150ms cubic-bezier(0, 0, 0.2, 1);
  }

  &:focus {
    outline: none;
  }
`;
function Y({
  trigger: o,
  children: i,
  container: a,
  dialogTitle: n,
  ...c
}) {
  const d = x(), p = (f) => {
    f.target !== d.current && f.preventDefault();
  };
  return /* @__PURE__ */ l(m, { ...c, children: [
    o,
    /* @__PURE__ */ e(t.Portal, { container: a, children: /* @__PURE__ */ e(U, { ref: d, children: /* @__PURE__ */ l(
      W,
      {
        onInteractOutside: p,
        "data-testid": "dialog-content",
        children: [
          n && /* @__PURE__ */ e(g, { className: "sr-only", children: n }),
          /* @__PURE__ */ l(q, { children: [
            /* @__PURE__ */ e(w, {}),
            i
          ] })
        ]
      }
    ) }) })
  ] });
}
const L = s`
  from {
    height: 0vh;
  }

  to {
    height: 93svh;
  }
`, v = D`
  background-color: ${({ theme: o }) => o.colors.white};
  border-radius: 0.8rem 0.8rem 0 0;
`, U = r(t.Overlay)`
  background-color: hsl(200deg 60% 12% / 75%);
  z-index: 9999;

  position: fixed;
  display: flex;
  justify-content: end;
  flex-direction: column;
  inset: 0;
  overflow-y: auto;
  backdrop-filter: blur(2px);

  @media (prefers-reduced-motion: no-preference) {
    &[data-state="open"] {
      animation: ${b} ${u}ms cubic-bezier(0, 0, 0.2, 1);
    }
  }
`, W = r(t.Content)`
  ${v};
  z-index: 99999;
  width: 100%;
  position: relative;
  height: 93svh;

  @media (prefers-reduced-motion: no-preference) {
    &[data-state="open"] {
      animation: ${L} ${u}ms ease;
    }
  }

  &:focus {
    outline: none;
  }
`, q = r.div`
  ${v};
  width: 100%;
  height: auto;
`;
export {
  oo as Dialog,
  Y as DialogBottomSheet,
  ao as DialogClose,
  no as DialogCloseIcon,
  eo as DialogContent,
  co as DialogDescription,
  V as DialogExtended,
  m as DialogRoot,
  po as DialogTitle,
  mo as DialogTrigger,
  Q as Drawer,
  P as DrawerContent,
  to as StyledDialogOverlay,
  R as StyledDrawerOverlay,
  uo as UnstyledDialogTrigger,
  O as contentShowLtr,
  I as contentShowRtl,
  u as duration,
  b as overlayShow
};
