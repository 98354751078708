import { useAxiosQuery } from "~/features/shared/hooks/useAxiosQuery";
import { useSavedConfigurationsService } from "~/features/shared/ServicesContext";

export const QUERY_KEY = "saved-configurations";

export function useSavedConfigurations() {
  const savedConfigurationsService = useSavedConfigurationsService();

  return useAxiosQuery(
    [QUERY_KEY],
    savedConfigurationsService.getSavedConfigurations,
    {
      keepPreviousData: false,
    },
  );
}
