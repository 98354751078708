import { useEffect, useState } from "react";

import { dispatchAdobeEvent } from "@intergamma/adobe-tracking";

import Cookies from "js-cookie";

import { useSessionData } from "../SessionDataContext";

const TRACKING_COOKIE_NAME = "sendIsLoggedInEvent";

export function useTrackingLoggedIn() {
  const sessionData = useSessionData();
  const [isLoggedInEventAlreadySent, setIsLoggedInEventAlreadySent] = useState(
    Cookies.get(TRACKING_COOKIE_NAME) === "true",
  );

  useEffect(() => {
    // Check for falsy values (true string will not pass)
    if (!isLoggedInEventAlreadySent && sessionData.isLoggedIn) {
      dispatchAdobeEvent({
        type: "is_logged_in",
        data: {
          kdbid: sessionData.uid,
        },
      });

      setIsLoggedInEventAlreadySent(true);
      Cookies.set(TRACKING_COOKIE_NAME, String(true));
    }

    if (!sessionData.isLoggedIn) {
      Cookies.set(TRACKING_COOKIE_NAME, String(false));
    }
  }, [isLoggedInEventAlreadySent, sessionData.isLoggedIn, sessionData.uid]);
}
