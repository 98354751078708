import { lazy } from "react";

import { useTranslation } from "react-i18next";
import { useRoutes } from "react-router-dom";

import { LanguageRedirect } from "./components/LanguageRedirect";
import { useBaseConfig } from "./config/MyAccountConfigContext";
import { useAuthenticatedRoutes } from "./config/routes/useAuthenticatedRoutes";
import { useGuestModeRoutes } from "./config/routes/useGuestModeRoutes";
import { useDesignSystemRoutes } from "./config/routes/useDesignSystemRoutes";
import { useRedirectRoutes } from "./config/routes/useRedirectRoutes";
import { useRegularRoutes } from "./config/routes/useRegularRoutes";
import { useSessionData } from "./features/session-data/SessionDataContext";
import { BaseLayout } from "./layouts/BaseLayout";
import { Layout } from "./layouts/Layout";
import { getLanguage } from "./helpers/getLanguage";

export const ErrorPage = lazy(() =>
  import("./components/ErrorPage").then((component) => ({
    default: component.ErrorPage,
  })),
);

export function Routes() {
  const { i18n } = useTranslation("urls");
  const { isLoggedIn } = useSessionData();
  const baseConfig = useBaseConfig();

  const language = getLanguage(i18n.language);

  const prefix = baseConfig.country !== "be" ? "/" : `/${language}`;

  const regularRoutes = useRegularRoutes();
  const designSystemRoutes = useDesignSystemRoutes();
  const authenticatedRoutes = useAuthenticatedRoutes();
  const guestModeRoutes = useGuestModeRoutes();
  const redirectsRoutes = useRedirectRoutes();

  const routes = useRoutes([
    {
      path: prefix,
      element: <BaseLayout />,
      children: [
        /* Rest paths */
        ...regularRoutes,

        /* Design system pages */
        ...designSystemRoutes,

        /* Guest mode only */
        ...(!isLoggedIn ? guestModeRoutes : []),

        /* Authenticated paths */
        ...(isLoggedIn ? authenticatedRoutes : []),
        {
          path: "*",
          element: <Layout />,
          children: [
            {
              path: "*",
              element: <ErrorPage type="NotFound" />,
            },
          ],
        },
      ],
    },
    /* Redirects */
    ...redirectsRoutes,
    {
      path: "*",
      element: baseConfig.country === "be" ? <LanguageRedirect /> : null,
    },
  ]);

  return routes;
}
